import { useLazyQuery } from '@apollo/client';
import { Spinner, Loading } from '@kalecard/common';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SimplePostComponent from '../components/posts/SimplePostComponent';
import { KALE_POSTS } from '../graphql/queries';
import { PostConnection, PostEdge } from '../__generated__/graphql';

interface KalePostsData {
  kalePosts: PostConnection;
}

export default function KalePostsPage() {
  const [edges, setEdges] = useState<PostEdge[]>([]);
  const [after, setAfter] = useState<string>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const onlyKaleBrands = queryParams.get('onlyKaleBrands');
  const challengeId = queryParams.get('challengeId');

  const loadPosts = (data: KalePostsData) => {
    console.log(data);
    if (data.kalePosts) {
      setHasNextPage(data.kalePosts.pageInfo.hasNextPage);
      setAfter(data.kalePosts.edges[data.kalePosts.edges.length - 1]?.cursor);
      setEdges([...edges, ...data.kalePosts.edges]);
    }
  };

  const [getPosts, { loading }] = useLazyQuery<KalePostsData>(KALE_POSTS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: loadPosts,
  });

  useEffect(() => {
    setEdges([]);
    setAfter(null);
    setHasNextPage(false);
    getPosts({
      variables: {
        first: 10,
        onlyKaleBrands: onlyKaleBrands === 'true',
        challengeId: challengeId,
      },
    });
  }, []);

  var listFooter = null;
  if (hasNextPage) {
    listFooter = (
      <button
        className="rounded border border-green-500 bg-transparent px-6 py-2 text-center font-semibold text-green-700 hover:border-transparent hover:bg-green-500 hover:text-white"
        onClick={() =>
          getPosts({
            variables: {
              first: 10,
              after,
              onlyKaleBrands: onlyKaleBrands === 'true',
              challengeId: challengeId,
            },
          })
        }
      >
        Load more
      </button>
    );
  }

  if (loading && edges.length === 0) {
    return <Loading />;
  }

  if (loading) {
    listFooter = (
      <div className="flex flex-wrap content-center">
        <Spinner size="h-6 w-6" />
      </div>
    );
  }

  return (
    <>
      <div className="m-4 flex flex-col items-center">
        <div className="mb-2 w-full overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden border-2 border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full table-fixed divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="w-2/5 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Caption
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Stats
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Media
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {edges.length === 0 && (
                    <tr>
                      <td>
                        <p className="my-2 flex w-full justify-center">
                          No posts yet 😢
                        </p>
                      </td>
                    </tr>
                  )}
                  {edges.map((edge) => (
                    <SimplePostComponent
                      key={edge.node.id}
                      post={edge.node}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {listFooter}
      </div>
    </>
  );
}
