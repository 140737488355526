import { useMutation } from "@apollo/client";
import { RESCORE_ACCOUNT } from "../../graphql/mutations";
import { Text } from "../catalyst/text";
import { Button } from "../catalyst/button";

export default function RescoreCreatorForm({
    creatorId,
    onSubmit
} : {
    creatorId: string,
    onSubmit: () => void;
}) {
    const [rescoreCreator] = useMutation(RESCORE_ACCOUNT);

    const handleRescore = async () => {
        try {
            await rescoreCreator({
                variables: {
                    creatorId: creatorId
                }
            });
            alert('Creator has been added back to the account review queue!');
        } catch (err) {
            console.log(err);
            alert('Something went wrong. Please try again later.');
        }
        onSubmit();
    }

    return (
        <div className="space-y-4">
            <Text>Upon clicking "Yes, rescore", the creator will get added back the Account Review queue. A Kale admin will then be able to score this creator account again. </Text>
            <div className="flex flex-row justify-center space-x-6">
                <Button outline onClick={onSubmit}>Cancel</Button>
                <Button color="emerald" onClick={handleRescore}>Yes, rescore</Button>
            </div>
        </div>
    );
}