import { useMutation, useQuery } from "@apollo/client";
import {
  AdminPermission,
  Dropdown,
  OptionInterface,
  Spinner,
} from "@kalecard/common";
import {
  ADMINS,
  ADMIN_PERMISSIONS,
  AdminPermissionsInterface,
  GET_BONSAI_QUEUES,
} from "../../graphql/queries";
import {
  Field,
  FieldGroup,
  Fieldset,
  Label,
  Legend,
} from "../catalyst/fieldset";
import { Input } from "../catalyst/input";
import { Strong, Text } from "../catalyst/text";
import { SubmitHandler, useForm } from "react-hook-form";
import { useCallback, useState } from "react";
import _ from "lodash";
import { Checkbox, CheckboxField } from "../catalyst/checkbox";
import { Button } from "../catalyst/button";
import { UPDATE_ADMIN } from "../../graphql/mutations";
import { adminRoles } from "../../utils/admins";
import { GetBonsaiQueuesQuery, KaleAdmin } from "../../__generated__/graphql";
import { Badge } from "../catalyst/badge";
import { XMarkIcon } from "@heroicons/react/24/outline";
import BonsaiQueuesDropdown from "../admins/BonsaiQueuesDropdown";

interface EditAdminInput {
  name: string;
}

export default function EditAdminForm({
  admin,
  onSubmit,
}: {
  admin: KaleAdmin;
  onSubmit: () => void;
}) {
  const [role, setRole] = useState(admin.role ?? adminRoles[2].id);
  const [permissionsMapping, setPermissionsMapping] = useState<
    Map<string, boolean>
  >(new Map());
  const [saveLoading, setIsSaveLoading] = useState(false);
  const [error, setError] = useState(null);
  const [bonsaiQueues, setBonsaiQueues] = useState(admin.bonsaiQueues ?? []);

  const setPermissions = useCallback(
    (permissions: AdminPermission[], role?: string) => {
      const newPermissionsMapping = new Map();
      permissions.forEach((permission) => {
        if (role) {
          newPermissionsMapping.set(
            permission.id,
            permission.roles.includes(role)
          );
        } else {
          newPermissionsMapping.set(
            permission.id,
            permission.mappedToCurrentAdmin === true
          );
        }
      });
      setPermissionsMapping(newPermissionsMapping);
    },
    []
  );

  const [updateAdmin] = useMutation(UPDATE_ADMIN, {
    refetchQueries: [ADMIN_PERMISSIONS, ADMINS],
  });

  const { data, loading } = useQuery<AdminPermissionsInterface>(
    ADMIN_PERMISSIONS,
    {
      variables: {
        adminId: admin.id,
      },
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => setPermissions(data.adminPermissions, null),
    }
  );

  const { data: queuesData, loading: queuesLoading } =
      useQuery<GetBonsaiQueuesQuery>(GET_BONSAI_QUEUES, {
        variables: {
          returnAll: true
        }
      });

  const { register, handleSubmit } = useForm<EditAdminInput>({
    defaultValues: {
      name: admin.name ?? "",
    },
  });

  const handlePermissionToggle = useCallback(
    (adminPermission: AdminPermission) => {
      setPermissionsMapping((prev) => {
        const newPermissionsMapping = new Map(prev);
        newPermissionsMapping.set(
          adminPermission.id,
          !prev.get(adminPermission.id)
        );
        return newPermissionsMapping;
      });
    },
    []
  );

  const handleRoleChange = useCallback(
    (newRole: OptionInterface) => {
      setRole(newRole.id);
      setPermissions(data.adminPermissions, newRole.id);
    },
    [data]
  );

  const submit: SubmitHandler<EditAdminInput> = async (
    data: EditAdminInput
  ) => {
    setIsSaveLoading(true);

    const permissionIds = Array.from(permissionsMapping)
      .filter(([key, value]) => value === true)
      .map(([key, value]) => key);

    try {
      await updateAdmin({
        variables: {
          adminId: admin.id,
          name: data.name,
          role: role,
          permissions: permissionIds,
          bonsaiQueues: bonsaiQueues.map(bonsaiQueue => bonsaiQueue.name)
        },
      });
      onSubmit();
    } catch (err) {
      console.log(err);
      setError("Failed to update this admin. Try again.");
    }
    setIsSaveLoading(false);
  };

  return (
    <form className="flex flex-col space-y-2 px-10">
      <div className="flex space-x-32">
        <Fieldset className="flex w-fit flex-col justify-between space-y-4">
          <Fieldset>
            <Legend>Admin Details</Legend>
            <Text className="text-nowrap pt-1">
              Editing details for: {admin.email}
            </Text>
            <FieldGroup>
              {/* Admin Name */}
              <Field className="w-fit">
                <div className="flex items-center space-x-4">
                  <Label className="font-medium">Name</Label>
                  <Input
                    name="name"
                    {...register("name", { required: true })}
                  />
                </div>
              </Field>

              {/* Admin Role */}
              <Field className="flex w-fit items-center space-x-4">
                <Label className="font-medium">Role</Label>
                <Dropdown
                  options={adminRoles}
                  activeOptionId={admin.role}
                  optionClicked={handleRoleChange}
                  label={""}
                />
              </Field>

              {!queuesLoading ? (
                <div className="space-y-2">
                  <Field className="space-x-4">
                    <Label className="font-medium">Bonsai Queues</Label>
                    <BonsaiQueuesDropdown bonsaiQueues={queuesData?.getQueues} selectedBonsaiQueues={bonsaiQueues} setSelectedBonsaiQueues={setBonsaiQueues} />
                  </Field>
                  <Badge color="indigo">{bonsaiQueues.length} selected queues</Badge>
                </div>
              ) : (
                <div className="flex flex-wrap content-center justify-center">
                  <Spinner size="h-6 w-6" />
                </div>
              )}
            </FieldGroup>
          </Fieldset>

          <Fieldset className="flex space-x-2">
            <Field>
              <Button
                color="emerald"
                className="hover:cursor-pointer"
                onClick={handleSubmit(submit)}
              >
                Save Changes
              </Button>
            </Field>
            {saveLoading && (
              <div className="flex flex-wrap content-center justify-center">
                <Spinner size="h-6 w-6" />
              </div>
            )}
          </Fieldset>
        </Fieldset>

        {!loading && permissionsMapping.size > 0 ? (
          <Fieldset className="w-full">
            {/* Admin Permissions */}
            <Field>
              <Label className="font-bold">Permissions</Label>
              <Field>
                {_.chain(data?.adminPermissions)
                  .groupBy("groupName")
                  .map((permissions, groupName) => [
                    <Field className="pt-2" key={groupName} />,
                    <Label className="capitalize" key={groupName + "label"}>
                      <Strong className="font-normal text-black">{`${groupName.toLowerCase()} Permissions`}</Strong>
                    </Label>,
                    <hr className="border-gray-200" key={groupName + "line"} />,
                    ...permissions.map((adminPermission) => (
                      <CheckboxField key={adminPermission.id + "field"}>
                        <Checkbox
                          key={adminPermission.id + "checkbox"}
                          name={adminPermission.permissionName}
                          value={adminPermission.permissionName}
                          checked={
                            permissionsMapping.get(adminPermission.id) ?? false
                          }
                          onChange={() =>
                            handlePermissionToggle(adminPermission)
                          }
                          color="emerald"
                        />
                        <Text className="text-gray-700">
                          {adminPermission.description}
                        </Text>
                      </CheckboxField>
                    )),
                  ])
                  .flatten()
                  .value()}
              </Field>
            </Field>
          </Fieldset>
        ) : (
          <div className="flex flex-wrap content-center justify-center">
            <Spinner size="h-6 w-6" />
          </div>
        )}
      </div>
      {error && <p className="text-sm font-medium text-red-500">{error}</p>}
    </form>
  );
}
