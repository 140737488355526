import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Dropdown, DropdownButton, DropdownMenu } from "../catalyst/dropdown";
import { BrandCategory } from "@kalecard/common";
import { Strong } from "../catalyst/text";
import { Switch, SwitchField } from "../catalyst/switch";
import { Label } from "../catalyst/fieldset";
import { useEffect, useState } from "react";
import { Checkbox, CheckboxField, CheckboxGroup } from "../catalyst/checkbox";
import { Badge } from "../catalyst/badge";
import { BonsaiQueue } from "../../__generated__/graphql";
import { Tooltip } from "flowbite-react";

export default function BonsaiQueuesDropdown({
  bonsaiQueues,
  selectedBonsaiQueues,
  setSelectedBonsaiQueues,
}: {
    bonsaiQueues: BonsaiQueue[],
    selectedBonsaiQueues: BonsaiQueue[],
    setSelectedBonsaiQueues: (selectedBonsaiQueues: BonsaiQueue[]) => void
}) {
  const [selectedQueues, setSelectedQueues] = useState(selectedBonsaiQueues);
  const [isAllQueuesChecked, setIsAllQueuesChecked] = useState(bonsaiQueues.length > 0 && selectedBonsaiQueues.length > 0 && selectedBonsaiQueues.every((queue) => selectedBonsaiQueues.includes(queue)));

  const handleAllQueuesToggle = () => {
    const updatedSelectedQueues = isAllQueuesChecked ? [] : bonsaiQueues;
    setSelectedBonsaiQueues(updatedSelectedQueues);
    setSelectedQueues(updatedSelectedQueues);
    setIsAllQueuesChecked(!isAllQueuesChecked);
  }

  const isQueueChecked = (queue: BonsaiQueue) => {
    return selectedBonsaiQueues.find((selectedQueue) => selectedQueue.name === queue.name) != null;
  }

  const handleQueueToggle = (queue: BonsaiQueue) => {
    const updatedSelectedQueues = isQueueChecked(queue)
      ? selectedBonsaiQueues.filter((selectedQueue) => selectedQueue.name !== queue.name)
      : [...selectedBonsaiQueues, queue];

      setSelectedBonsaiQueues(updatedSelectedQueues);
      setSelectedQueues(updatedSelectedQueues);
  }

  const handleOnlyQueueClick = (queue: BonsaiQueue) => {
    const updatedSelectedCategories = bonsaiQueues.filter((bonsaiQueue) => bonsaiQueue.name === queue.name);
    setSelectedBonsaiQueues(updatedSelectedCategories);
    setSelectedQueues(updatedSelectedCategories);
  }

  useEffect(() => {
    const allQueuesChecked = bonsaiQueues.length > 0 && selectedQueues.length > 0 && bonsaiQueues.every((queue) => selectedQueues.find((selectedQueue) => selectedQueue.name === queue.name) != null);
    setIsAllQueuesChecked(allQueuesChecked);
  }, [selectedQueues]);

  return (
    <Dropdown>
      <DropdownButton outline className="bg-white">
        <ChevronDownIcon className="h-4 w-4" />
      </DropdownButton>
      <DropdownMenu>
        <div className="flex flex-col p-4 space-y-2">
          <div>
            <SwitchField>
              <Label><Strong>Select all queues</Strong></Label>
              <Switch name="all_queues" color="emerald" checked={isAllQueuesChecked} onClick={handleAllQueuesToggle}></Switch>
            </SwitchField>
          </div>
          <div>
            <CheckboxGroup>
              {bonsaiQueues.map((bonsaiQueue) => (
                <CheckboxFieldWithHover key={bonsaiQueue.name} bonsaiQueue={bonsaiQueue} isQueueChecked={isQueueChecked} handleQueueToggle={handleQueueToggle} handleOnlyQueueClick={handleOnlyQueueClick} />
              ))}
            </CheckboxGroup>
          </div>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
}

function CheckboxFieldWithHover({ bonsaiQueue, isQueueChecked, handleQueueToggle, handleOnlyQueueClick }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <CheckboxField
      key={bonsaiQueue.name}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Checkbox
        name={bonsaiQueue.name}
        color="emerald"
        checked={isQueueChecked(bonsaiQueue)}
        onClick={() => handleQueueToggle(bonsaiQueue)}
      />
      <div className="flex justify-between">
        <Tooltip content={bonsaiQueue.name}>
            <Label>{bonsaiQueue.friendlyName}</Label>
        </Tooltip>
        {isHovered && <Badge color="emerald" className="hover:cursor-pointer" onClick={() => handleOnlyQueueClick(bonsaiQueue)}>Only</Badge>}
      </div>
    </CheckboxField>
  );
}