/* This DealsSelectMenu requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { useMutation } from '@apollo/client';
import { ADD_DEAL_TO_POST } from '../../graphql/mutations';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { classNames } from '../../utils/style';
import { DealEdge, Post, Deal } from '../../__generated__/graphql';

interface DealsSelectMenuInterface {
  post: Post;
  deals: DealEdge[];
  selectedDeal?: Deal;
}

export default function DealsSelectMenu(props: DealsSelectMenuInterface) {
  const [initDeals, selectedDealIndex] = initializeDeals(
    props.deals,
    props.selectedDeal
  );
  const [deals, setDeals] = useState<Deal[]>(initDeals);
  const [selected, setSelected] = useState<Deal>(
    deals[selectedDealIndex === -1 ? 0 : selectedDealIndex]
  );
  const [addDealToPost] = useMutation(ADD_DEAL_TO_POST);
  const [error, setError] = useState<string>(null);

  const selectDeal = async (selected: Deal) => {
    console.log(selected);
    setSelected(selected);
    setError(null);
    try {
      const result = await addDealToPost({
        variables: {
          postId: props.post.id,
          dealId: selected.id,
        },
      });
      console.log(result);
    } catch (err) {
      console.error(err);
      setError("Failed. Don't close this tab!!. Contact Luis.");
    } finally {
      // setIsLoading(false);
    }
  };

  return (
    <Listbox
      value={selected}
      onChange={selectDeal}
      disabled={true}
    >
      <Listbox.Label className="block text-sm font-medium text-gray-700">
        Assign deal{' '}
        {error && <span className="font-bold text-red-500">{error}</span>}
      </Listbox.Label>
      <div className="relative mt-1">
        <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
          <span className="inline-flex w-full truncate">
            <span className="truncate">
              {selected && getMerchantName(selected)}
            </span>
            <span className="ml-2 truncate text-gray-500">
              {selected?.reward && `${selected.reward.toFixed(2)}%`}
            </span>
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {deals.map((deal) => {
              return (
                <Listbox.Option
                  key={deal.id}
                  className={({ active }) =>
                    classNames(
                      active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                      'relative cursor-default select-none py-2 pl-3 pr-9'
                    )
                  }
                  value={deal}
                >
                  {({ selected, active }) => (
                    <>
                      <div className="flex">
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'truncate'
                          )}
                        >
                          {getMerchantName(deal)}
                        </span>
                        <span
                          className={classNames(
                            active ? 'text-indigo-200' : 'text-gray-500',
                            'ml-2 truncate'
                          )}
                        >
                          {deal.reward && `${deal.reward.toFixed(2)}%`}
                        </span>
                      </div>

                      {selected ? (
                        <span
                          className={classNames(
                            active ? 'text-white' : 'text-indigo-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          )}
                        >
                          <CheckIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              );
            })}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}

function getMerchantName(deal: Deal): string {
  return deal.merchantInstagramHandle
    ? '@' + deal.merchantInstagramHandle
    : deal.merchantName
    ? deal.merchantName
    : deal.merchant;
}

function initializeDeals(
  dealEdges: DealEdge[],
  selectedDeal?: Deal
): [Deal[], number] {
  var selectedIndex = -1;

  const deals = dealEdges.map((edge, index) => {
    if (edge.node.id === selectedDeal?.id) {
      selectedIndex = index;
    }
    return edge.node;
  });

  if (selectedIndex === -1 && selectedDeal) {
    deals.unshift(selectedDeal);
    selectedIndex = 0;
  }

  return [
    [{ merchant: 'Choose a deal', id: '-1' } as Deal, ...deals],
    selectedIndex + 1,
  ];
}
