import { useMutation, useQuery } from '@apollo/client';
import {
  GET_SHARED_BONSAI_TASK,
  GetSharedBonsaiTaskQuery,
} from '../graphql/queries';
import { AMEND_BONSAI_BUNDLE } from '../graphql/mutations';
import { bonsaiRegistry } from '../components/bonsai/BonsaiRegistry';
import { useParams, useHistory } from 'react-router-dom';
import { toBonsaiQueue } from '../components/bonsai/BonsaiModels';
import { BonsaiHeader } from '../components/bonsai/BonsaiContainer';

interface BonsaiAmendPageProps {}

export default function BonsaiAmendPage(props: BonsaiAmendPageProps) {
  const params = useParams<{ id: string; userId: string }>();
  const history = useHistory();
  const userId = parseInt(params['userId']);

  // Get the task by ID
  const { data, loading } = useQuery<GetSharedBonsaiTaskQuery>(
    GET_SHARED_BONSAI_TASK,
    {
      variables: {
        taskId: parseInt(params.id),
      },
    }
  );

  const [amendBonsaiBundle] = useMutation(AMEND_BONSAI_BUNDLE);

  if (loading) return <div>Loading...</div>;

  const queue = toBonsaiQueue(data.getTask.queueName);

  const onAmendTask = async (outcome: any) => {
    try {
      const outcomeString = JSON.stringify(outcome);
      await amendBonsaiBundle({
        variables: {
          queueName: data.getTask.queueName,
          taskBonsaiUserId: userId,
          taskId: parseInt(params.id),
          outcome: outcomeString,
        },
      });

      // Navigate back after successful completion
      history.push('/bonsai');
    } catch (error) {
      console.error('Error amending task:', error);
    }
  };

  return (
    <div className="mx-auto flex h-full w-full max-w-6xl flex-col space-y-4 p-4">
      <BonsaiHeader
        setQueue={() => history.push('/bonsai')}
        backDisabled={false}
      />

      <div className="py-4">
        <h1 className="text-xl font-semibold text-gray-900">
          Amend Task #{userId}
        </h1>
        <p className="mt-2 text-sm text-gray-700">
          Make your changes and submit to amend this task.
        </p>
      </div>

      {bonsaiRegistry(
        {
          type: queue,
          name: data.getTask.queueName,
          friendlyName: `Amend Task #${userId}`,
          taskDescription: 'Task amendment',
        },
        onAmendTask,
        data.getTask.metadata,
        userId
      )}
    </div>
  );
}
