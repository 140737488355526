import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Description,
  Field,
  FieldGroup,
  Fieldset,
  Label,
  Legend,
} from '../../catalyst/fieldset';
import { Input } from '../../catalyst/input';
import { Button } from '../../catalyst/button';
import { Badge } from '../../catalyst/badge';
import { Strong, Text } from '../../catalyst/text';
import BrandsDropdown from '../BrandsDropdown';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Brand, OptionInterface, Spinner } from '@kalecard/common';
import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_POST_REQUIREMENT,
  UPDATE_BRAND_CONTENT_INFO
} from '../../../graphql/mutations';
import { POST_REQUIREMENTS } from '../../../graphql/queries';
import {
  Brand as BrandGenerated,
  PostRequirement,
} from '../../../__generated__/graphql';
import { Checkbox, CheckboxField } from '../../catalyst/checkbox';
import { faInstagram, faPinterest, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface BrandContentInfoInput {
  newRequiredHashtag: string;
  newKaleHashtag: string;
}

export default function BrandContentInfo({ brand }) {
  const { data: brandPostRequirementsData } = useQuery(POST_REQUIREMENTS, {
    variables: { brandId: brand.id },
  });
  const [createPostRequirement] = useMutation(CREATE_POST_REQUIREMENT);

  const [allowedPlatforms, setAllowedPlatforms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const timerRef = useRef<NodeJS.Timeout>();
  const clearErrorMessage = () => {
    timerRef.current = setTimeout(() => setErrorMessage(null), 5000);
  };
  const clearSuccessMessage = () => {
    timerRef.current = setTimeout(() => setSuccessMessage(null), 7000);
  };

  const initializeBrandOptions = (
    brands: Brand[],
    setBrandOptions: React.Dispatch<React.SetStateAction<OptionInterface[]>>
  ) => {
    const options = brands.map((brand) => {
      return { id: brand?.id, name: brand?.name, imageUrl: brand?.logoUrl };
    });
    setBrandOptions(options);
  };

  const [requiredHashtags, setRequiredHashtags] = useState<PostRequirement[]>(
    []
  );
  useEffect(() => {
    const hashTagRequirements =
      brandPostRequirementsData?.postRequirements.filter(
        (requirement) => requirement.type === 'HASHTAG'
      );
    if (hashTagRequirements) {
      setRequiredHashtags(hashTagRequirements as PostRequirement[]);
    }

    const platformRequirements = brandPostRequirementsData?.postRequirements?.filter(
      postRequirement => postRequirement.type === "PLATFORM"
    );
    if (platformRequirements) {
      setAllowedPlatforms(platformRequirements.map(postRequirement => postRequirement.externalPlatform));
    }

  }, [brandPostRequirementsData]);

  const [deniedBrands, setDeniedBrands] = useState<OptionInterface[]>(null);

  const [updateBrandContentInfo] = useMutation(UPDATE_BRAND_CONTENT_INFO, {
    refetchQueries: [POST_REQUIREMENTS]
  });

  const { register, handleSubmit, getValues, reset } =
    useForm<BrandContentInfoInput>();

  const addBrandOption = (
    brandOption: OptionInterface,
    optionGroup: OptionInterface[],
    setOptionGroup: React.Dispatch<React.SetStateAction<OptionInterface[]>>
  ) => {
    const existingBrandOption = optionGroup.find(
      (option) => option.id === brandOption.id
    );
    if (!existingBrandOption) {
      setOptionGroup([...optionGroup, brandOption]);
    }
  };

  const removeBrandOption = (
    brandOption: OptionInterface,
    optionGroup: OptionInterface[],
    setOptionGroup: React.Dispatch<React.SetStateAction<OptionInterface[]>>
  ) => {
    const updatedBrandOptions = optionGroup.filter(
      (option) => option.id !== brandOption.id
    );
    setOptionGroup(updatedBrandOptions);
  };

  useEffect(() => {
    brand.deniedBrands
      ? initializeBrandOptions(brand.deniedBrands, setDeniedBrands)
      : setDeniedBrands([]);
  }, []);

  const onSubmit = async () => {
    setLoading(true);
    try {
      const variables = {
        brandId: brand.id,
        deniedBrands: deniedBrands.map((brand) => brand.id),
        allowedPlatforms: allowedPlatforms,
        hashtagRequirementIds: requiredHashtags.map((requirement) => requirement.id)
      };

      await updateBrandContentInfo({
        variables: variables,
      });
      setErrorMessage(null);
      setSuccessMessage('Congrats! Your changes have been applied.');
      clearSuccessMessage();
    } catch (err) {
      console.error(err);
      setErrorMessage('Failed to apply your changes, try again later.');
      clearErrorMessage();
    }
    setLoading(false);
  };

  const addRequiredHashtag = async () => {
    let newHashtag = getValues('newRequiredHashtag');
    if (!newHashtag.startsWith('#')) {
      newHashtag = `#${newHashtag}`;
    }
    const newReq = await createPostRequirement({
      variables: {
        type: 'HASHTAG',
        requirement: newHashtag,
      },
    });
    setRequiredHashtags([
      ...requiredHashtags,
      newReq?.data?.createPostRequirement as PostRequirement,
    ]);
    reset();
  };

  const handleCheckboxChange = (platform: string) => {
    if (allowedPlatforms.includes(platform)) {
      setAllowedPlatforms(allowedPlatforms.filter(item => item !== platform));
    } else {
      setAllowedPlatforms([...allowedPlatforms, platform]);
    }
  }

  return (
    <form
      className="space-y-4"
      key="payments"
    >
      <Fieldset>
        <div className="flex justify-center">
          <Legend>Content Requirements</Legend>
        </div>
        <FieldGroup>
          <div className="flex justify-between space-x-10">
            <Field className="w-full space-y-2">
              <Label>Required Hashtags</Label>
              <Description>
                The following hashtag(s) must be included in the post.
              </Description>
              <div className="flex space-x-2">
                <Input
                  placeholder="#partner"
                  {...register('newRequiredHashtag')}
                  type="text"
                />
                <Button
                  color="emerald"
                  onClick={(e) => {
                    e.stopPropagation();
                    addRequiredHashtag();
                  }}
                >
                  Add
                </Button>
              </div>

              <div className="flex flex-wrap gap-2">
                {requiredHashtags.map((req) => (
                  <Badge
                    color="lime"
                    className="space-x-1"
                  >
                    <Text>
                      <Strong>{req.requirement}</Strong>
                    </Text>
                    <XMarkIcon
                      className="h-4 w-4 text-gray-500"
                      onClick={() => {
                        const newRequiredHashtags = requiredHashtags.filter(
                          (requirement) => requirement.id !== req.id
                        );
                        setRequiredHashtags(newRequiredHashtags);
                      }}
                    />
                  </Badge>
                ))}
              </div>

              {requiredHashtags.length == 0 && (
                <p className="text-sm font-medium text-red-500">
                  Must have at least one required hashtag.
                </p>
              )}
            </Field>
            <Field className="w-full space-y-2">
              <Label>Blocked Brands</Label>
              <BrandsDropdown
                brandId={''}
                setBrand={(option) =>
                  addBrandOption(option, deniedBrands, setDeniedBrands)
                }
                setBrandId={() => null}
              />
              <Description>
                The following brand(s) will not show up on the earn tab for the
                current brand's creators.
              </Description>

              <div className="flex flex-wrap gap-2">
                {deniedBrands?.map((deniedBrand) => (
                  <Badge
                    key={deniedBrand.id}
                    color="lime"
                    className="space-x-1"
                  >
                    <Text>
                      <Strong>{deniedBrand.name}</Strong>
                    </Text>
                    <XMarkIcon
                      className="h-4 w-4 text-gray-500"
                      onClick={() =>
                        removeBrandOption(
                          deniedBrand,
                          deniedBrands,
                          setDeniedBrands
                        )
                      }
                    />
                  </Badge>
                ))}
              </div>
            </Field>
          </div>
        </FieldGroup>
        <Legend>Allowed Platforms</Legend>
        <Text>Allow users to submit posts for this brand from the selected platforms below.</Text>
        <div className="flex flex-wrap gap-6 pt-2">
          <CheckboxField>
            <Checkbox name="instagram" value="INSTAGRAM" color="emerald" checked={allowedPlatforms.includes("INSTAGRAM")} onClick={() => handleCheckboxChange("INSTAGRAM")} />
            <div className="flex items-center space-x-2">
              <FontAwesomeIcon
                className="ml-auto"
                icon={faInstagram as IconProp}
                size="sm"
              />
              <Label>Instagram</Label>
            </div>
          </CheckboxField>
          <CheckboxField>
            <Checkbox name="tiktok" value="TIKTOK" color="emerald" checked={allowedPlatforms.includes("TIKTOK")} onClick={() => handleCheckboxChange("TIKTOK")} />
            <div className="flex items-center space-x-2">
              <FontAwesomeIcon
                className="ml-auto"
                icon={faTiktok as IconProp}
                size="sm"
              />
              <Label>TikTok</Label>
            </div>
          </CheckboxField>
          <CheckboxField>
            <Checkbox name="youtube" value="YOUTUBE" color="emerald" checked={allowedPlatforms.includes("YOUTUBE")} onClick={() => handleCheckboxChange("YOUTUBE")} />
            <div className="flex items-center space-x-2">
              <FontAwesomeIcon
                className="ml-auto"
                icon={faYoutube as IconProp}
                size="sm"
              />
              <Label>YouTube</Label>
            </div>
          </CheckboxField>
          <CheckboxField>
            <Checkbox name="pinterest" value="PINTEREST" color="emerald" checked={allowedPlatforms.includes("PINTEREST")} onClick={() => handleCheckboxChange("PINTEREST")} />
            <div className="flex items-center space-x-2">
            <FontAwesomeIcon
                className="ml-auto"
                icon={faPinterest as IconProp}
                size="sm"
              />
              <Label>Pinterest</Label>
            </div>
          </CheckboxField>
        </div>
      </Fieldset>
      <div className="flex flex-row items-center justify-end space-x-4">
        {successMessage && (
          <p className="text-sm font-medium text-green-500">{successMessage}</p>
        )}
        {errorMessage && (
          <p className="text-sm font-medium text-red-500">{errorMessage}</p>
        )}
        {loading && (
          <div className="flex flex-wrap content-center justify-center pr-2">
            <Spinner size="h-6 w-6" />
          </div>
        )}
        <Button
          color="indigo"
          onClick={handleSubmit(onSubmit)}
        >
          Save Changes
        </Button>
      </div>
    </form>
  );
}

export function isBrandContentInfoStepComplete(brand: BrandGenerated): boolean {
  return (brand.hashtagRequirements ?? []).length > 0;
}
