import { ImageWithTracking, prettyDecimal } from '@kalecard/common';
import { BuildingStorefrontIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import { TableCell, TableRow } from '../catalyst/table';
import { Strong, Text, TextLink } from '../catalyst/text';
import { Badge } from '../catalyst/badge';
import { Button } from '../catalyst/button';
import { Brand } from '../../__generated__/graphql';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getExternalPlatformIcon } from '../../utils/externalPlatform';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export default function BrandRow({
  brand,
  isKaleBrand,
}: {
  brand: Brand;
  isKaleBrand: boolean;
}) {
  return (
    <TableRow key={brand.id}>
      {/* Name Column */}
      <TableCell>
        <div className="flex items-center space-x-4">
          {/* Logo and ID */}
          <div className="flex flex-col items-center space-y-1">
            {brand.logoUrl ? (
              <ImageWithTracking
                component="BrandRow"
                className="h-10 w-10 rounded-full border border-kale-green-500"
                src={brand.logoUrl}
                alt={brand.name}
              />
            ) : (
              <>
                <BuildingStorefrontIcon className="inline-block h-10 w-10 rounded-full" />
              </>
            )}
            <Text>ID: {brand.id}</Text>
          </div>

          {/* Name and handles */}
          <div>
            <Link
              to={`/brands/${brand.id}/setup`}
              className="text-gray-900 hover:text-gray-500"
            >
              <Text>
                <Strong>{brand.name}</Strong>
              </Text>
            </Link>
            {brand.socialHandle && (
              <Text className="flex items-center space-x-1">
                <span>
                  <svg
                    fill="currentColor"
                    viewBox="0 0 25 25"
                    width="18px"
                    height="18px"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                <TextLink
                  target="_blank"
                  href={`https://instagram.com/${brand.socialHandle}`}
                  className="no-underline"
                >
                  {brand.socialHandle}
                </TextLink>
              </Text>
            )}
            {brand.tiktokSocialHandle && (
              <Text className="flex items-center space-x-1">
                <span>
                  <svg
                    fill="currentColor"
                    viewBox="0 0 30 30"
                    width="18px"
                    height="18px"
                  >
                    <path d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h18c1.105,0,2-0.895,2-2V6C26,4.895,25.104,4,24,4z M22.689,13.474 c-0.13,0.012-0.261,0.02-0.393,0.02c-1.495,0-2.809-0.768-3.574-1.931c0,3.049,0,6.519,0,6.577c0,2.685-2.177,4.861-4.861,4.861 C11.177,23,9,20.823,9,18.139c0-2.685,2.177-4.861,4.861-4.861c0.102,0,0.201,0.009,0.3,0.015v2.396c-0.1-0.012-0.197-0.03-0.3-0.03 c-1.37,0-2.481,1.111-2.481,2.481s1.11,2.481,2.481,2.481c1.371,0,2.581-1.08,2.581-2.45c0-0.055,0.024-11.17,0.024-11.17h2.289 c0.215,2.047,1.868,3.663,3.934,3.811V13.474z" />
                  </svg>
                </span>
                <TextLink
                  target="_blank"
                  href={`https://tiktok.com/@${brand.tiktokSocialHandle}`}
                  className="no-underline"
                >
                  {brand.tiktokSocialHandle}
                </TextLink>
              </Text>
            )}
          </div>
        </div>
      </TableCell>

      {isKaleBrand && (
        <>
          {/* $$$ Column */}
          <TableCell>
            <Text>
              Platform Fee:
              <Strong> ${prettyDecimal(brand.platformFee)}</Strong>
            </Text>
            <Text>
              Max Content Budget:
              <Strong> ${prettyDecimal(brand.contentBudgetMax)}</Strong>
            </Text>
            <Text>
              Current Content Budget:
              <Strong>
                {brand.contentBudget
                  ? ` $${prettyDecimal(brand.contentBudget)}`
                  : ' N/A'}
              </Strong>
            </Text>
            <Text>
              Max Creator Payout:
              <Strong> ${prettyDecimal(brand.maxCreatorPayout)}</Strong>
            </Text>
            <Text>
              Monthly Max Creator Payout:
              <Strong>
                {brand.monthlyMaxCreatorPayout
                  ? ` $${prettyDecimal(brand.monthlyMaxCreatorPayout / 100)}`
                  : ' N/A'}
              </Strong>
            </Text>
            <Text>
              Payment Period:
              <Strong>{` Net${brand.paymentPeriodDays}`}</Strong>
            </Text>
          </TableCell>

          {/* Settings Column */}
          <TableCell>
            <div className="flex space-x-4">
              <div className="space-y-1">
                <Text className="pr-2">Instagram Auto DM</Text>
                <Text>Platform Fee Recurring Charges</Text>
                <Text>Challenges Paused</Text>
                <Text>Kale Offset</Text>
                <Text className="pr-2">Requires High Quality Score</Text>
                <Text>Pacing With Sage</Text>
                <Badge color={brand.autoRechargeEnabled ? 'emerald' : 'blue'}>
                  {brand.autoRechargeEnabled
                    ? 'Monthly Content Budget'
                    : 'Campaign Content Budget'}
                </Badge>
                <div className="space-x-1">
                  {brand.postRequirements?.filter((postRequirement) => postRequirement.type === "PLATFORM").map((postRequirement) =>
                      <FontAwesomeIcon
                        className={'h-4 w-4 text-black'}
                        icon={
                          getExternalPlatformIcon(
                            postRequirement.externalPlatform
                          ) as IconProp
                        }
                      />
                    )}
                </div>
              </div>
              <div className="flex flex-col space-y-1">
                <Badge color={brand.isIgStoryReplyEnabled ? 'emerald' : 'red'}>
                  {brand.isIgStoryReplyEnabled ? 'On' : 'Off'}
                </Badge>
                <Badge
                  color={brand.recurringChargesEnabled ? 'emerald' : 'red'}
                >
                  {brand.recurringChargesEnabled ? 'On' : 'Off'}
                </Badge>
                <Badge color={brand.challengesPaused ? 'emerald' : 'red'}>
                  {brand.challengesPaused ? 'On' : 'Off'}
                </Badge>
                <Badge color={brand.isKaleOffsetEnabled ? 'emerald' : 'red'}>
                  {brand.isKaleOffsetEnabled ? 'On' : 'Off'}
                </Badge>
                <Badge
                  color={brand.requiresHighQualityScore ? 'emerald' : 'red'}
                >
                  {brand.requiresHighQualityScore ? 'On' : 'Off'}
                </Badge>
                <Badge color={brand.isPacingWithSage ? 'emerald' : 'red'}>
                  {brand.isPacingWithSage ? 'On' : 'Off'}
                </Badge>
              </div>
            </div>
          </TableCell>
        </>
      )}

      <TableCell>
        <Button color="emerald">
          <Link to={`/brands/${brand.id}/setup`}>View</Link>
        </Button>
      </TableCell>
    </TableRow>
  );
}
