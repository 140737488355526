import {
  Brand,
  GetBrandPayoutStructureQuery,
  GetBrandPayoutStructureQueryVariables,
} from '../__generated__/graphql';
import { GET_BRAND_PAYOUT_STRUCTURES } from '../graphql/queries';
import { useMutation, useQuery } from '@apollo/client';
import PageLayout, {
  PageActions,
  PageContent,
  PageSection,
  PageSectionList,
} from '../components/layout/AdminPageLayout';
import { Button } from '../components/catalyst/button';
import { PlusIcon } from '@heroicons/react/24/outline';
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from '../components/catalyst/dialog';
import React, { useState } from 'react';
import {
  Description,
  Field,
  FieldGroup,
  Fieldset,
  Label,
  Legend,
} from '../components/catalyst/fieldset';
import { Input } from '../components/catalyst/input';
import { CREATE_BRAND_PAYOUT_STRUCTURE } from '../graphql/mutations';
import { Controller, useForm } from 'react-hook-form';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../components/catalyst/table';
import { Badge } from '../components/catalyst/badge';
import { Radio, RadioField, RadioGroup } from '../components/catalyst/radio';
import { formatCurrency } from '../utils/currency';

interface BrandPayoutStructureV2PageProps {
  brand: Brand;
}

export default function BrandPayoutStructureV2Page({
  brand,
}: BrandPayoutStructureV2PageProps) {
  const [newStructureDialogOpen, setNewStructureDialogOpen] = useState(false);

  const { data, loading, error, refetch } = useQuery<
    GetBrandPayoutStructureQuery,
    GetBrandPayoutStructureQueryVariables
  >(GET_BRAND_PAYOUT_STRUCTURES, {
    variables: {
      brandId: brand.id,
    },
  });

  const handleNewStructureButtonClick = () => {
    setNewStructureDialogOpen(true);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      <PageLayout>
        <PageContent>
          <PageSectionList>
            <PageSection
              empty={data.brandPayoutStructures.length <= 0}
              title={'Existing Payout Structures'}
              emptyPrompt={"This brand doesn't have any payout structures yet"}
              actions={
                <PageActions>
                  <Button
                    outline
                    onClick={handleNewStructureButtonClick}
                  >
                    <PlusIcon />
                  </Button>
                </PageActions>
              }
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeader>Status</TableHeader>
                    <TableHeader>Content Commission</TableHeader>
                    <TableHeader>Max Payout</TableHeader>
                    <TableHeader>Cost per Video</TableHeader>
                    <TableHeader>Kale Commission per Video</TableHeader>
                    <TableHeader>Creator Payment per Video</TableHeader>
                    <TableHeader># Deals</TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.brandPayoutStructures.map((structure) => (
                    <TableRow key={structure.id}>
                      <TableCell className="font-medium">
                        {structure.active ? (
                          <Badge color="green">Active</Badge>
                        ) : (
                          <Badge color="amber">Inactive</Badge>
                        )}
                      </TableCell>
                      <TableCell>
                        {structure.structure.contentCommissionPercentage}%
                      </TableCell>
                      <TableCell>{structure.structure.maxPayout}</TableCell>
                      <TableCell>
                        {structure.stats
                          ? formatCurrency(
                              structure.stats.totalPayout /
                                structure.stats.dealCount
                            )
                          : '-'}
                      </TableCell>
                      <TableCell>
                        {structure.stats
                          ? formatCurrency(
                              structure.stats.kalePayout /
                                structure.stats.dealCount
                            )
                          : '-'}
                      </TableCell>
                      <TableCell>
                        {structure.stats
                          ? formatCurrency(
                              structure.stats.creatorPayout /
                                structure.stats.dealCount
                            )
                          : '-'}
                      </TableCell>
                      <TableCell>
                        {structure.stats ? structure.stats.dealCount : '-'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </PageSection>
          </PageSectionList>
        </PageContent>
      </PageLayout>

      <NewBrandPayoutStructureDialog
        brandId={brand.id}
        cpvDisabled={data.brandPayoutStructures.length <= 0}
        open={newStructureDialogOpen}
        onClose={() => setNewStructureDialogOpen(false)}
        onSubmit={async () => {
          await refetch();
          setNewStructureDialogOpen(false);
        }}
      />
    </>
  );
}

interface NewBrandPayoutStructureDialogProps {
  brandId: string;
  open: boolean;
  cpvDisabled: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

type FormValues = {
  creationMethod: 'DESIRED_COST_PER_VIDEO' | 'CONTENT_COMMISSION_PERCENTAGE';
  desiredCostPerVideo?: number;
  contentCommissionPercentage?: number;
  maxPayout?: number;
};

export function NewBrandPayoutStructureDialog({
  brandId,
  open,
  cpvDisabled,
  onClose,
  onSubmit,
}: NewBrandPayoutStructureDialogProps) {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      creationMethod: 'CONTENT_COMMISSION_PERCENTAGE',
      desiredCostPerVideo: undefined,
      contentCommissionPercentage: undefined,
      maxPayout: undefined,
    },
  });

  const creationMethod = watch('creationMethod');

  const [createBrandPayoutStructure] = useMutation<
    any,
    {
      brandId: string;
      creationMethod: string;
      desiredCostPerVideo?: number;
      contentCommissionPercentage?: number;
    }
  >(CREATE_BRAND_PAYOUT_STRUCTURE);

  const onFormSubmit = async (data: FormValues) => {
    const variables: any = {
      brandId: brandId,
      creationMethod: data.creationMethod,
    };

    if (data.creationMethod === 'DESIRED_COST_PER_VIDEO') {
      variables.desiredCostPerVideo = data.desiredCostPerVideo;
    } else if (data.creationMethod === 'CONTENT_COMMISSION_PERCENTAGE') {
      variables.contentCommissionPercentage = data.contentCommissionPercentage;
    }

    // modify dollar values to be in cents
    variables.desiredCostPerVideo *= 100;
    variables.maxPayout *= 100;

    await createBrandPayoutStructure({ variables });
    onSubmit();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>New Payout Structure</DialogTitle>
      <DialogDescription>
        Payout structures consist of two parts: content commission percentage
        and max payout. The content commission percentage is the portion of a
        creator's video payout that Kale will keep.
      </DialogDescription>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <DialogBody>
          <Fieldset>
            <FieldGroup>
              <Legend className="-mb-4">Creation Method</Legend>
              <Controller
                name="creationMethod"
                control={control}
                render={({ field }) => (
                  <>
                    <RadioGroup
                      value={field.value}
                      onChange={field.onChange}
                    >
                      <RadioField>
                        <Radio
                          color="green"
                          value="CONTENT_COMMISSION_PERCENTAGE"
                        />
                        <Label>Commission Percentage</Label>
                        <Description>
                          Directly specify the content commission percentage.
                        </Description>
                      </RadioField>
                      <RadioField disabled={true}>
                        <Radio
                          color="green"
                          value="DESIRED_COST_PER_VIDEO"
                        />
                        <Label className="line-through">Cost per Video</Label>
                        <Description>
                          This should be done through Hubspot
                        </Description>
                      </RadioField>
                    </RadioGroup>
                  </>
                )}
              />

              {creationMethod === 'DESIRED_COST_PER_VIDEO' && (
                <Field>
                  <Label>Desired Cost Per Video</Label>
                  <Input
                    type="number"
                    step={1}
                    placeholder="50"
                    disabled={cpvDisabled}
                    {...register('desiredCostPerVideo', {
                      valueAsNumber: true,
                    })}
                    name="desiredCostPerVideo"
                  />
                  <Description>Value in dollars.</Description>
                </Field>
              )}

              {creationMethod === 'CONTENT_COMMISSION_PERCENTAGE' && (
                <Field>
                  <Label>Content Commission Percentage</Label>
                  <Input
                    type="number"
                    step={1}
                    placeholder="40"
                    {...register('contentCommissionPercentage', {
                      valueAsNumber: true,
                    })}
                    name="contentCommissionPercentage"
                  />
                  <Description>Value as a percentage.</Description>
                </Field>
              )}

              <Field>
                <Label>Max Payout</Label>
                <Input
                  type="number"
                  step={1}
                  {...register('maxPayout', { valueAsNumber: true })}
                  name="maxPayout"
                  placeholder={'250'}
                />
                <Description>
                  The maximum amount that a brand will pay for a video. Leave
                  blank for no maximum.
                </Description>
              </Field>
            </FieldGroup>
          </Fieldset>
        </DialogBody>
        <DialogActions>
          <Button
            plain
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            color="green"
            type="submit"
            disabled={isSubmitting}
          >
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
