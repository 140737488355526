import { gql } from '../__generated__';

export const UPDATE_FACEBOOK_ACCESS_TOKEN = gql(`
  mutation UpdateFacebookAccessToken(
    $accessToken: String!
    $scopes: String!
    $shopId: ID!
  ) {
    updateFacebookAccessToken(
      accessToken: $accessToken
      scopes: $scopes
      shopId: $shopId
    )
  }
`);
export const MESSAGE_CREATOR = gql(`
  mutation MessageCreator($userId: ID!, $text: String!) {
    messageCreator(userId: $userId, text: $text)
  }
`);
export const CREATE_NOTIFICATION_CAMPAIGN = gql(`
  mutation CreateNotificationCampaign(
    $name: String!, 
    $textMessage: String!, 
    $targetType: String!, 
    $targetQueryId: Int, 
    $targetCreatorIds: [ID!],
    $scheduleType: String!,
    $cronExpression: String,
  ) {
    createNotificationCampaign(
        name: $name, 
        textMessage: $textMessage, 
        targetType: $targetType, 
        targetQueryId: $targetQueryId, 
        targetCreatorIds: $targetCreatorIds,
        scheduleType: $scheduleType,
        cronExpression: $cronExpression
    ) {
      id
    }
  }
`);
export const UNDO_DEAL = gql(`
  mutation UndoDeal($dealId: ID!, $shouldRefund: Boolean) {
    undoDeal(dealId: $dealId, shouldRefund: $shouldRefund)
  }
`);
export const GET_UPLOAD_SIGNED_URL = gql(`
  mutation GetUploadSignedUrl(
    $mimeType: String!
    $uploadType: String!
    $metadata: UploadRequestMetadata
  ) {
    getUploadSignedUrl(
      mimeType: $mimeType
      uploadType: $uploadType
      metadata: $metadata
    ) {
      mimeType
      uploadType
      bucketPath
      signedUrl
    }
  }
`);

export const DOWNLOAD_MEDIA = gql(`
  mutation DownloadMedia($postId: ID!) {
    downloadMedia(postId: $postId)
  }
`);

export const ADD_DEAL_TO_POST = gql(`
  mutation AddDealToPost($postId: ID!, $dealId: ID!) {
    addDealToPost(postId: $postId, dealId: $dealId)
  }
`);
gql(`
  mutation RefreshUserSocialData($userId: ID!) {
    refreshUserSocialData(userId: $userId)
  }
`);

export const REMOVE_EXTERNAL_USER = gql(`
  mutation RemoveExternalUser($externalUserId: ID!) {
    removeExternalUser(externalUserId: $externalUserId) {
      id
    }
  }
`);
export const NEW_POST = gql(`
  mutation NewPost(
    $userId: ID!
    $externalPlatform: String!
    $caption: String
    $productType: String!
    $mediaType: String!
    $externalPermalink: String!
    $username: String!
  ) {
    newPost(
      userId: $userId
      externalPlatform: $externalPlatform
      caption: $caption
      productType: $productType
      mediaType: $mediaType
      externalPermalink: $externalPermalink
      username: $username
    )
  }
`);

export const UPDATE_POST_CHILD = gql(`
  mutation UpdatePostChild($childId: ID!, $isBrandPost: Boolean!) {
    updatePostChild(childId: $childId, isBrandPost: $isBrandPost)
  }
`);

export const UPDATE_POST_METRICS = gql(`
  mutation UpdatePostMetrics(
    $postId: ID!
    $impressions: String!
    $likes: String!
    $comments: String!
    $isFirstMetricsRecording: Boolean!
  ) {
    updatePostMetrics(
      postId: $postId
      impressions: $impressions
      likes: $likes
      comments: $comments
      isFirstMetricsRecording: $isFirstMetricsRecording
    )
  }
`);

export const CREATE_CHALLENGE = gql(`
  mutation CreateChallenge(
    $brandId: ID!
    $description: String!
    $tagRequirements: String
    $audienceType: String!
    $brandIds: [ID!]
    $exampleUrl: String
    $difficulty: Int
    $startDate: String
    $endDate: String
    $brandProductId: ID
    $challengePostRequirements: [ID!]
    $title: String!
    $type: String
    $templateId: ID
  ) {
    createChallenge(
      brandId: $brandId
      description: $description
      tagRequirements: $tagRequirements
      audienceType: $audienceType
      brandIds: $brandIds
      exampleUrl: $exampleUrl
      difficulty: $difficulty
      startDate: $startDate
      endDate: $endDate
      brandProductId: $brandProductId
      challengePostRequirements: $challengePostRequirements
      title: $title
      type: $type
      templateId: $templateId
    ) {
      id
      description
      mentionRequirements
      tagRequirements
      createdAt
      state
      brand {
        id
        name
        logoUrl
        postRequirements {
          id
          requirement
          emoji
          type
          externalPlatform
          isDefault
          referenceBrand {
            id
            name
            socialHandle
            tiktokSocialHandle
            pinterestSocialHandle
            youtubeSocialHandle
          }
        }
      }
      audienceType
      audience {
        brands {
          id
          name
        }
        brandCategories {
          id
          name
        }
        creators {
          id
          name
        }
      }
      exampleUrl
      difficulty
      totalPosts
      totalClaims
      postRate
      startDate
      endDate
      challengeTemplate {
        id
        title
      }
      requiredBrandProducts {
        id
        externalUrl
      }
      postRequirements {
        id
        requirement
        emoji
        type
        externalPlatform
        isDefault
        referenceBrand {
          id
          name
          socialHandle
          tiktokSocialHandle
          pinterestSocialHandle
          youtubeSocialHandle
        }
      }
      title
      type
    }
  }
`);

export const UPDATE_CHALLENGE = gql(`
  mutation UpdateChallenge(
    $challengeId: ID!
    $description: String
    $tagRequirements: String
    $state: String
    $audienceType: String
    $brandIds: [ID!]
    $brandCategoryIds: [ID!]
    $creatorIds: [ID!]
    $exampleUrl: String
    $difficulty: Int
    $startDate: String
    $endDate: String
    $brandProductId: ID
    $challengePostRequirements: [ID!]
    $templateId: ID
    $title: String
  ) {
    updateChallenge(
      challengeId: $challengeId
      description: $description
      tagRequirements: $tagRequirements
      state: $state
      audienceType: $audienceType
      brandIds: $brandIds
      brandCategoryIds: $brandCategoryIds
      creatorIds: $creatorIds
      exampleUrl: $exampleUrl
      difficulty: $difficulty
      startDate: $startDate
      endDate: $endDate
      brandProductId: $brandProductId
      challengePostRequirements: $challengePostRequirements
      templateId: $templateId
      title: $title
    ) {
      id
      description
      mentionRequirements
      tagRequirements
      createdAt
      state
      brand {
        id
        name
        logoUrl
        postRequirements {
          id
          requirement
          emoji
          type
          externalPlatform
          isDefault
          referenceBrand {
            id
            name
            socialHandle
            tiktokSocialHandle
            pinterestSocialHandle
            youtubeSocialHandle
          }
        }
      }
      audienceType
      audience {
        brands {
          id
          name
        }
        brandCategories {
          id
          name
        }
        creators {
          id
          name
        }
      }
      exampleUrl
      difficulty
      totalPosts
      totalClaims
      postRate
      startDate
      endDate
      challengeTemplate {
        id
        title
      }
      feedback {
        id
        createdAt
        feedback
        actorName
        status
      }
      requiredBrandProducts {
        id
        externalUrl
      }
      postRequirements {
        id
        requirement
        emoji
        type
        externalPlatform
        isDefault
        referenceBrand {
          id
          name
          socialHandle
          tiktokSocialHandle
          pinterestSocialHandle
          youtubeSocialHandle
        }
      }
      title
      type
    }
  }
`);

export const TOGGLE_CHALLENGE_STATE = gql(`
  mutation ToggleChallengeState($challengeId: ID!) {
    toggleChallengeState(challengeId: $challengeId)
  }
`);

export const SET_POST_REWARD_CATEGORY = gql(`
  mutation SetPostRewardCategory(
    $postId: ID!
    $rewardCategory: String!
    $mainPostId: ID
  ) {
    setPostRewardCategory(
      postId: $postId
      rewardCategory: $rewardCategory
      mainPostId: $mainPostId
    )
  }
`);

export const REMOVE_POST_REWARD_CATEGORY = gql(`
  mutation RemovePostRewardCategory($postId: ID!) {
    removePostRewardCategory(postId: $postId)
  }
`);

export const APPROVE_RECEIPT_DEAL = gql(`
  mutation ApproveReceiptDeal($dealId: ID!) {
    approveReceiptDeal(dealId: $dealId)
  }
`);

export const UPDATE_CHALLENGE_ID_FOR_DEAL = gql(`
  mutation UpdateChallengeIdForDeal($dealId: ID!, $challengeId: ID!) {
    updateChallengeIdForDeal(dealId: $dealId, challengeId: $challengeId)
  }
`);

export const UPDATE_BRAND_LANDING_PAGE = gql(`
  mutation UpdateBrandLandingPage(
    $brandId: ID!
    $videos: [BrandLandingPageVideoInput]
  ) {
    updateBrandLandingPage(brandId: $brandId, videos: $videos) {
      brand {
        id
      }
    }
  }
`);

export const ADD_NEW_SOCIAL_HANDLE = gql(`
  mutation AddNewSocialHandle(
    $creatorId: ID!
    $socialHandle: String!
    $externalPlatform: String!
  ) {
    addNewSocialHandle(
      creatorId: $creatorId
      socialHandle: $socialHandle
      externalPlatform: $externalPlatform
    )
  }
`);

export const BRAND_TRANSACTION = gql(`
  mutation BrandTransaction(
    $brandId: ID!
    $transactionType: String!
    $amount: Float!
    $date: String
    $stripePaymentIntentId: String
    $description: String
    $postURL: String
    $whitelistRequestId: ID
    $paymentMethod: String
    $recordOnly: Boolean
  ) {
    brandTransaction(
      brandId: $brandId
      transactionType: $transactionType
      amount: $amount
      date: $date
      stripePaymentIntentId: $stripePaymentIntentId
      description: $description
      postURL: $postURL
      whitelistRequestId: $whitelistRequestId
      paymentMethod: $paymentMethod
      recordOnly: $recordOnly
    )
  }
`);

export const CREATOR_TRANSACTION = gql(`
  mutation CreatorTransaction(
    $creatorId: ID!
    $transactionType: String!
    $amount: Int!
    $timestamp: String
    $brandId: ID
    $whitelistRequestId: ID
    $messageToCreator: String
    $postURL: String
  ) {
    creatorTransaction(
      creatorId: $creatorId
      transactionType: $transactionType
      amount: $amount
      timestamp: $timestamp
      brandId: $brandId
      whitelistRequestId: $whitelistRequestId
      messageToCreator: $messageToCreator
      postURL: $postURL
    )
  }
`);

export const UPDATE_CONTENT_WHITELIST = gql(`
  mutation UpdateContentWhitelist(
    $postWhitelistRequestId: ID!
    $state: String
  ) {
    updateContentWhitelist(
      postWhitelistRequestId: $postWhitelistRequestId
      state: $state
    )
  }
`);

export const RESET_CREATOR_BIRTHDATE = gql(`
  mutation ResetCreatorBirthdate($creatorId: ID!) {
    resetCreatorBirthdate(creatorId: $creatorId)
  }
`);

export const UPDATE_CREATOR_ACCOUNT_STATE = gql(`
  mutation UpdateCreatorAccountState(
    $creatorId: ID!
    $accountState: String!
    $accountStateType: String
    $reason: String
  ) {
    updateCreatorAccountState(
      creatorId: $creatorId
      accountState: $accountState
      accountStateType: $accountStateType
      reason: $reason
    )
  }
`);

export const SET_BRAND_PAUSE_CHALLENGES = gql(`
  mutation SetBrandPauseChallenges($brandId: ID!, $shouldPause: Boolean!) {
    setBrandPauseChallenges(brandId: $brandId, shouldPause: $shouldPause)
  }
`);

export const UDPATE_CHALLENGE_FEEDBACK = gql(`
  mutation UpdateChallengeFeedback($challengeFeedbackId: ID!, $status: String) {
    updateChallengeFeedback(
      challengeFeedbackId: $challengeFeedbackId
      status: $status
    )
  }
`);

export const ADD_POST_REQUIREMENT = gql(`
  mutation AddPostRequirement(
    $brandId: ID
    $requirement: String!
    $iconFilename: String
    $emoji: String
  ) {
    addPostRequirement(
      brandId: $brandId
      requirement: $requirement
      iconFilename: $iconFilename
      emoji: $emoji
    ) {
      id
      requirement
      iconFilename
      emoji
    }
  }
`);

export const UPDATE_POST_REQUIREMENT = gql(`
  mutation UpdatePostRequirement(
    $postRequirementId: ID!
    $requirement: String
    $iconFilename: String
    $emoji: String
  ) {
    updatePostRequirement(
      postRequirementId: $postRequirementId
      requirement: $requirement
      iconFilename: $iconFilename
      emoji: $emoji
    ) {
      id
      requirement
      iconFilename
      emoji
    }
  }
`);

export const UPDATE_BRAND_PAYMENT_STRUCTURE = gql(`
  mutation UpdateBrandPaymentStructure(
    $brandPaymentId: ID!
    $minQualityScore: Float
    $kaleOriginationFee: Float
    $creatorOriginationFee: Float
    $kaleCpmFee: Float
    $creatorCpmFee: Float
  ) {
    updateBrandPaymentStructure(
      brandPaymentId: $brandPaymentId
      minQualityScore: $minQualityScore
      kaleOriginationFee: $kaleOriginationFee
      creatorOriginationFee: $creatorOriginationFee
      kaleCpmFee: $kaleCpmFee
      creatorCpmFee: $creatorCpmFee
    ) {
      id
      brand {
        id
      }
      minQualityScore
      productType
      kaleOriginationFee
      creatorOriginationFee
      kaleCpmFee
      creatorCpmFee
    }
  }
`);

export const CREATE_BRAND_PAYMENT_STRUCTURE = gql(`
  mutation CreateBrandPaymentStructure(
    $brandId: ID
    $productType: String
    $minQualityScore: Float!
    $kaleOriginationFee: Float!
    $creatorOriginationFee: Float!
    $kaleCpmFee: Float!
    $creatorCpmFee: Float!
  ) {
    createBrandPaymentStructure(
      brandId: $brandId
      productType: $productType
      minQualityScore: $minQualityScore
      kaleOriginationFee: $kaleOriginationFee
      creatorOriginationFee: $creatorOriginationFee
      kaleCpmFee: $kaleCpmFee
      creatorCpmFee: $creatorCpmFee
    ) {
      id
      brand {
        id
      }
      minQualityScore
      productType
      kaleOriginationFee
      creatorOriginationFee
      kaleCpmFee
      creatorCpmFee
    }
  }
`);

export const DELETE_BRAND_PAYMENT_STRUCTURE = gql(`
  mutation DeleteBrandPaymentStructure($brandPaymentId: ID!) {
    deleteBrandPaymentStructure(brandPaymentId: $brandPaymentId)
  }
`);

export const COMPLETE_BONSAI_TASK = gql(`
  mutation CompleteBonsaiTask(
    $queueName: String!
    $taskId: Int!
    $outcome: JSON!
  ) {
    completeBonsaiTask(
      queueName: $queueName
      taskId: $taskId
      outcome: $outcome
    ) {
      taskId
    }
  }
`);

export const CREATE_BRAND_PRODUCT = gql(`
  mutation CreateBrandProduct($brandId: ID!, $externalUrl: String!) {
    createBrandProduct(brandId: $brandId, externalUrl: $externalUrl) {
      id
      externalUrl
    }
  }
`);

export const UPDATE_ADMIN = gql(`
  mutation UpdateAdmin(
    $adminId: ID!
    $name: String!
    $role: String!
    $permissions: [ID!]!
    $bonsaiQueues: [String!]
  ) {
    updateAdmin(
      adminId: $adminId
      name: $name
      role: $role
      permissions: $permissions
      bonsaiQueues: $bonsaiQueues
    ) {
      id
      name
      email
      role
      bonsaiQueues {
        name
        friendlyName
        taskDescription
      }
    }
  }
`);

export const UPDATE_ADMIN_PERMISSION = gql(`
  mutation UpdateAdminPermission(
    $adminPermissionId: ID!
    $description: String!
    $roles: [String!]!
    $updateAdminToPermissionMappings: Boolean!
  ) {
    updateAdminPermission(
      adminPermissionId: $adminPermissionId
      description: $description
      roles: $roles
      updateAdminToPermissionMappings: $updateAdminToPermissionMappings
    ) {
      id
      groupName
      permissionName
      description
      roles
    }
  }
`);

export const CREATE_ADMIN_PERMISSION = gql(`
  mutation CreateAdminPermission(
    $groupName: String!
    $permissionName: String!
    $description: String!
    $roles: [String!]!
    $updateAdminToPermissionMappings: Boolean!
  ) {
    createAdminPermission(
      groupName: $groupName
      permissionName: $permissionName
      description: $description
      roles: $roles
      updateAdminToPermissionMappings: $updateAdminToPermissionMappings
    ) {
      id
      groupName
      permissionName
      description
      roles
    }
  }
`);

export const SET_BRAND_SAGE_PACING = gql(`
  mutation SetBrandSagePacing($id: ID!, $enablePacing: Boolean!) {
    setBrandSagePacing(brandId: $id, enablePacing: $enablePacing) {
      ...SageSettingsFragment
    }
  }
`);

export const SET_BRAND_SAGE_CUSTOM_SCHEDULE = gql(`
  mutation SetBrandSageCustomSchedule($id: ID!, $schedule: CreateCustomSageSchedule) {
    setBrandCustomSageSchedule(brandId: $id, schedule: $schedule) {
      ...SageSettingsFragment
    }
  }
`);

export const ADD_CHALLENGE_BUDGET = gql(`
  mutation AddSageChallengeBudget($id: ID!, $challengeId: Int!, $budget: Int!) {
    addSageChallengeBudget(brandId: $id, challengeId: $challengeId, budget: $budget) {
      ...SageSettingsFragment
    }
  }
`);

export const UPDATE_CHALLENGE_BUDGET = gql(`
  mutation UpdateSageChallengeBudget($brandId: ID!, $challengeId: Int!, $budget: Int!) {
    updateSageChallengeBudget(brandId: $brandId, challengeId: $challengeId, budget: $budget) {
      ...SageSettingsFragment
    }
  }
`);

export const REMOVE_CHALLENGE_BUDGET = gql(`
  mutation RemoveSageChallengeBudget($brandId: ID!, $challengeId: Int!) {
    removeSageChallengeBudget(brandId: $brandId, challengeId: $challengeId) {
      ...SageSettingsFragment
    }
  }
`);

export const UPDATE_BRAND_DETAILS = gql(`
  mutation UpdateBrandDetails($brandId: ID!, $socialHandle: String!, $tiktokSocialHandle: String!, $referralCode: String!, $brandCategoryIds: [ID!]!, $salesRepAdminId: String, $accountManagerAdminId: String, $youtubeSocialHandle: String, $snapchatSocialHandle: String, $pinterestSocialHandle: String) {
    updateBrandDetails(brandId: $brandId, socialHandle: $socialHandle, tiktokSocialHandle: $tiktokSocialHandle, referralCode: $referralCode, brandCategoryIds: $brandCategoryIds, salesRepAdminId: $salesRepAdminId, accountManagerAdminId: $accountManagerAdminId, youtubeSocialHandle: $youtubeSocialHandle, snapchatSocialHandle: $snapchatSocialHandle, pinterestSocialHandle: $pinterestSocialHandle) {
      id
      socialHandle
      tiktokSocialHandle
      youtubeSocialHandle
      snapchatSocialHandle
      pinterestSocialHandle
      referralCode
      brandCategories {
        id
        name
      }
      salesRep {
        id
        name
      }
      accountManager {
        id
        name
      }
      status
    }
  }
`);

export const UPDATE_BRAND_ASSETS = gql(`
  mutation UpdateBrandAssets($brandId: ID!, $logoBucketPath: String, $coverBucketPath: String) {
    updateBrandAssets(brandId: $brandId, logoBucketPath: $logoBucketPath, coverBucketPath: $coverBucketPath) {
      id
      logoUrl
      coverUrl
      status
    }
  }
`);

export const UPDATE_BRAND_SETTINGS = gql(`
  mutation UpdateBrandSettings(
    $brandId: ID!,
    $requiresHighQualityScore: Boolean!,
    $isOffersActive: Boolean!,
    $isChallengeReviewRequired: Boolean!,
    $isSelfService: Boolean!,
    $minCreatorAgeYears: Int!,
    $maxCreatorAgeYears: Int!,
  ) {
    updateBrandSettings(
      brandId: $brandId,
      requiresHighQualityScore: $requiresHighQualityScore,
      isOffersActive: $isOffersActive,
      isChallengeReviewRequired: $isChallengeReviewRequired,
      isSelfService: $isSelfService,
      minCreatorAgeYears: $minCreatorAgeYears,
      maxCreatorAgeYears: $maxCreatorAgeYears
    ) {
      id
      requiresHighQualityScore
      isOffersActive
      isChallengeReviewRequired
      status
      isSelfService
      minCreatorAgeYears
      maxCreatorAgeYears
    }
  }
`);

export const UPDATE_BRAND_ADMINS_AND_ROLES = gql(`
  mutation UpdateBrandAdminsAndRoles(
    $brandId: ID!,
    $admins: [AdminInput!]!
  ) {
    updateBrandAdminsAndRoles(brandId: $brandId, admins: $admins) {
      id
      admins {
        id
        name
        email
        role
        administeredBrands {
          brand {
            id
          }
          role
        }
      }
      status
    }
  }
`);

export const UPDATE_BRAND_CONTENT_INFO = gql(`
  mutation UpdateBrandContentInfo(
    $brandId: ID!,
    $deniedBrands: [ID!],
    $allowedPlatforms: [String!]
    $hashtagRequirementIds: [ID!]
  ) {
    updateBrandContentInfo(
      brandId: $brandId,
      deniedBrands: $deniedBrands,
      allowedPlatforms: $allowedPlatforms
      hashtagRequirementIds: $hashtagRequirementIds
    ) {
      id
      hashtagRequirements
      deniedBrands {
        id
        name
      }
      status
      postRequirements {
        id
        requirement
        type
        externalPlatform
      }
    }
  }
`);

export const UPDATE_BRAND_INSTAGRAM_INFO = gql(`
  mutation UpdateBrandInstagramInfo(
    $brandId: ID!
    $isIgStoryReplyEnabled: Boolean!
    $maxFollowerCount: Float
    $minFollowerCount: Float
    $title: String
    $subtitle: String
    $imageBucketPath: String
  ) {
    updateBrandInstagramInfo(
      brandId: $brandId
      isIgStoryReplyEnabled: $isIgStoryReplyEnabled
      maxFollowerCount: $maxFollowerCount
      minFollowerCount: $minFollowerCount
      title: $title
      subtitle: $subtitle
      imageBucketPath: $imageBucketPath
    ) {
      id
      isIgStoryReplyEnabled
      instagramAutomatedMessageSettings {
        title
        subtitle
        maxFollowerCount
        minFollowerCount
        imageUrl
      }
    }
  }
`);

export const UPDATE_BRAND_AVAILABILITY_MUTATION = gql(`
  mutation UpdateBrandAvailability($brandId: ID!, $countryCodeAllowList: [String!]!) {
    updateBrandAvailability(brandId: $brandId, countryCodeAllowList: $countryCodeAllowList) {
      id
      countryCodeAllowList
    }
  }
`);

export const TURN_BRAND_ON_AS_KALE_BRAND = gql(`
  mutation TurnBrandOnAsKaleBrand($brandId: ID!) {
    turnBrandOnAsKaleBrand(brandId: $brandId) {
      id
      isKaleBrand
      status
    }
  }
`);

export const UPDATE_BRAND_CREATOR_PAYMENTS_INFO = gql(`
  mutation UpdateBrandCreatorPaymentsInfo(
    $brandId: ID!,
    $maxCreatorPayout: Float,
    $monthlyMaxCreatorPayout: Float,
    $monthlyMaxCreatorChallenges: Int,
    $isKaleOffsetEnabled: Boolean!,
  ) {
    updateBrandCreatorPaymentsInfo(
      brandId: $brandId,
      maxCreatorPayout: $maxCreatorPayout,
      monthlyMaxCreatorPayout: $monthlyMaxCreatorPayout,
      monthlyMaxCreatorChallenges: $monthlyMaxCreatorChallenges,
      isKaleOffsetEnabled: $isKaleOffsetEnabled,
    ) {
      id
      maxCreatorPayout
      monthlyMaxCreatorPayout
      monthlyMaxCreatorChallenges
      status
      isKaleOffsetEnabled
    }
  }
`);

export const UPDATE_BRAND_INVOICING_INFO = gql(`
  mutation UpdateBrandInvoicingInfo(
    $brandId: ID!,
    $recurringChargesEnabled: Boolean!,
    $autoRechargeEnabled: Boolean!,
    $autoChargeDefaultPaymentMethod: Boolean!,
    $enableWireTransferInvoicePayment: Boolean!,
    $paymentPeriodDays: Int!,
    $invoiceEmailAddress: String!
  ) {
    updateBrandInvoicingInfo(
      brandId: $brandId,
      recurringChargesEnabled: $recurringChargesEnabled,
      autoRechargeEnabled: $autoRechargeEnabled,
      autoChargeDefaultPaymentMethod: $autoChargeDefaultPaymentMethod,
      enableWireTransferInvoicePayment: $enableWireTransferInvoicePayment,
      paymentPeriodDays: $paymentPeriodDays,
      invoiceEmailAddress: $invoiceEmailAddress
    ) {
      id
      recurringChargesEnabled
      autoRechargeEnabled
      autoChargeDefaultPaymentMethod
      enableWireTransferInvoicePayment
      invoiceEmailAddress
      status
      paymentPeriodDays
    }
  }
`);

export const UNDO_USER_STRIKE = gql(`
  mutation UndoUserStrike($creatorId: ID!, $strikeId: ID!) {
    undoUserStrike(creatorId: $creatorId, strikeId: $strikeId) {
      id
    }
  }
`);

export const CREATE_CHALLENGE_TEMPLATE = gql(`
  mutation CreateChallengeTemplate($title: String!, $soundUrl: String, $exampleUrl: String, $templateType: String!, $template: String, $brandCategoryIds: [ID!], $shouldCreateChallengeRequests: Boolean!, $challengeTemplateTagIds: [ID!]) {
    createChallengeTemplate(title: $title, soundUrl: $soundUrl, exampleUrl: $exampleUrl, templateType: $templateType, template: $template, brandCategoryIds: $brandCategoryIds, shouldCreateChallengeRequests: $shouldCreateChallengeRequests, challengeTemplateTagIds: $challengeTemplateTagIds) {
      id
      title
      template
      soundUrl
      exampleUrl
      createdAt
      status
      templateType
      tags {
        id
        name
      }
      brandCategories {
        id
        name
      }
    }
  }  
`);

export const DEACTIVATE_CHALLENGE_TEMPLATE = gql(`
  mutation DeactivateChallengeTemplate($challengeTemplateId: ID!) {
    deactivateChallengeTemplate(challengeTemplateId: $challengeTemplateId) {
      id
      title
      template
      soundUrl
      exampleUrl
      createdAt
      status
      templateType
      tags {
        id
        name
      }
    }
  }    
`);

export const UPDATE_CHALLENGE_TEMPLATE = gql(`
  mutation UpdateChallengeTemplate($challengeTemplateId: ID!, $template: String, $title: String, $soundUrl: String, $exampleUrl: String, $status: String, $challengeTemplateTagIds: [ID!], $brandCategoryIds: [ID!]) {
    updateChallengeTemplate(challengeTemplateId: $challengeTemplateId, template: $template, title: $title, soundUrl: $soundUrl, exampleUrl: $exampleUrl, status: $status, challengeTemplateTagIds: $challengeTemplateTagIds, brandCategoryIds: $brandCategoryIds) {
      id
      template
      title
      soundUrl
      exampleUrl
      status
      tags {
        id
        name
      }
      brandCategories {
        id
        name
      }
    }
  }  
`);

export const CREATE_POST_REQUIREMENT = gql(`
  mutation CreatePostRequirement($requirement: String!, $emoji: String, $type: String!, $externalPlatform: String, $isDefault: Boolean) {
    createPostRequirement(requirement: $requirement, emoji: $emoji, type: $type, externalPlatform: $externalPlatform, isDefault: $isDefault) {
      id
      requirement
      emoji
      type
      externalPlatform
      isDefault
      referenceBrand {
        id
        name
        socialHandle
        tiktokSocialHandle
        pinterestSocialHandle
        youtubeSocialHandle
      }
    }
  }
`);

export const ADD_POST_REQUIREMENT_FOR_BRAND = gql(`
  mutation AddPostRequirementForBrand($brandId: ID!, $postRequirementId: ID!, $applyToExistingChallenges: Boolean!, $applyToFutureChallenges: Boolean!) {
    addPostRequirementForBrand(brandId: $brandId, postRequirementId: $postRequirementId, applyToExistingChallenges: $applyToExistingChallenges, applyToFutureChallenges: $applyToFutureChallenges) {
      id
      requirement
      emoji
      type
      externalPlatform
      isDefault
      isMappedToBrand(brandId: $brandId)
      mappedActiveChallengesCount(brandId: $brandId)
    }
  }
`);

export const REMOVE_POST_REQUIREMENT_FOR_BRAND = gql(`
  mutation RemovePostRequirementForBrand($brandId: ID!, $postRequirementId: ID!, $applyToExistingChallenges: Boolean!, $applyToFutureChallenges: Boolean!) {
    removePostRequirementForBrand(brandId: $brandId, postRequirementId: $postRequirementId, applyToExistingChallenges: $applyToExistingChallenges, applyToFutureChallenges: $applyToFutureChallenges) {
      id
      requirement
      emoji
      type
      externalPlatform
      isDefault
      isMappedToBrand(brandId: $brandId)
      mappedActiveChallengesCount(brandId: $brandId)
    }
  }
`);

export const CREATE_BRAND_PAYOUT_STRUCTURE = gql(`
  mutation CreateBrandPayoutStructure($brandId: ID!, $creationMethod: String!, $desiredCostPerVideo: Int, $contentCommissionPercentage: Int) { 
    createBrandPayoutStructure(brandId: $brandId, creationMethod: $creationMethod, desiredCostPerVideo: $desiredCostPerVideo, contentCommissionPercentage: $contentCommissionPercentage) {
      id
      active
      structure {
        contentCommissionPercentage
        maxPayout
      }
    }
  }
`);

export const UPDATE_CHALLENGE_PHOTO_INSPIRATION_MEMBERSHIP = gql(`
  mutation UpdateChallengePhotoInspirationMembership($challengeId: ID!, $photosToAdd: [ID!], $photosToRemove: [ID!]) {
    updateChallengePhotoInspirationMembership(challengeId: $challengeId, photosToAdd: $photosToAdd, photosToRemove: $photosToRemove) {
      id
    }
  }
`);

export const TRIGGER_BRAND_CONTENT_BUDGET_INVOICE = gql(`
  mutation TriggerBrandContentBudgetInvoice($brandId: ID!) {
    triggerBrandContentBudgetBilling(brandId: $brandId) {
      id
      remainingBudgetUntilCharged
    }
  }
`);

export const UPDATE_POST_REQUIREMENT_MAPPINGS = gql(`
  mutation UpdatePostRequirementMappings($postRequirementId: ID!, $brandCategoryIds: [ID!], $challengeTemplateIds: [ID!], $challengeTypes: [String!]) {
    updatePostRequirementMappings(postRequirementId: $postRequirementId, brandCategoryIds: $brandCategoryIds, challengeTemplateIds: $challengeTemplateIds, challengeTypes: $challengeTypes) {
      id
      requirement
      emoji
      type
      externalPlatform
      isDefault
      referenceBrand {
        id
        name
        socialHandle
        tiktokSocialHandle
        pinterestSocialHandle
        youtubeSocialHandle
      }
      mappings {
        brandCategories {
          id
          name
        }
        challengeTemplates {
          id
          title
        }
        challengeTypes
      }
    }
  }
`);

export const AMEND_BONSAI_BUNDLE = gql(`
  mutation AmendBonsaiBundle($queueName: String, $taskBonsaiUserId: Int!, $taskId: Int!, $outcome: JSON!) {
    amendBonsaiBundle(queueName: $queueName, taskBonsaiUserId: $taskBonsaiUserId, taskId: $taskId, outcome: $outcome)
  }
`);

export const RESCORE_ACCOUNT = gql(`
  mutation RescoreAccount($creatorId: ID!) {
    reScoreCreator(creatorId: $creatorId)
  }
`);
