import { BonsaiQueue } from '../BonsaiModels';
import { BonsaiActionPanel } from '../BonsaiContainer';
import {
  BonsaiOutcome,
  BonsaiPanel,
  Loading,
  OutcomeControls,
} from '@kalecard/common';
import { Strong, Text } from '../../catalyst/text';
import { Link } from '../../catalyst/link';
import { TemporalDownloadPostMediaWorkflowLink } from '../../Links';
import UploadMedia from '../../forms/UploadMedia';
import { useState } from 'react';

interface ManualDownloadToolInput {
  postId: number;
  dealId: number;
  creatorId: string;
  externalPermalink: string;
  externalUserId: string;
  externalPlatform: string;
}

interface ManualDownloadToolOutput {
  outcome: BonsaiOutcome;
}

interface ManualDownloadToolProps {
  queue: BonsaiQueue;
  onComplete: (outcome: ManualDownloadToolOutput) => void;
  readOnly: boolean;
  taskId: number;
  input: ManualDownloadToolInput;
}

export function ManualDownloadTool(props: ManualDownloadToolProps) {
  console.log(props);
  const [selectedOutcome, setSelectedOutcome] =
    useState<BonsaiOutcome>(undefined);
  const [showLoaderForMediaComponent, setShowLoaderForMediaComponent] =
    useState<boolean>(false);
  return (
    <>
      <div>
        <BonsaiPanel>
          <div className="flex h-full flex-col justify-between p-4">
            <div className="text-md text-center font-semibold text-slate-700">
              Manual Download Information
            </div>
            <Text>
              <Strong>Post ID:</Strong> {props.input.postId}
            </Text>
            {props.input.externalPlatform && (
              <Text>
                <Strong>External Platform:</Strong>{' '}
                {props.input.externalPlatform}
              </Text>
            )}
            <Text>
              <Strong>Deal ID:</Strong> {props.input.dealId}
            </Text>
            <Text>
              <Strong>Workflow Link: </Strong>{' '}
              <TemporalDownloadPostMediaWorkflowLink
                postId={props?.input?.postId?.toString()}
              />
            </Text>
            <Text>
              <Strong>Creator ID:</Strong>{' '}
              <Link
                target="_blank"
                className="text-sm text-kale-green-500 underline hover:text-kale-green-400"
                href={`/creators/${props.input.creatorId}/deals`}
              >
                {props.input.creatorId}
              </Link>
            </Text>
            {props.input.externalUserId && (
              <Text>
                <Strong>External User ID:</Strong> {props.input.externalUserId}
              </Text>
            )}
            <Text>
              <Strong>External Link:</Strong>{' '}
              <Link
                target="_blank"
                className="text-sm text-kale-green-500 underline hover:text-kale-green-400"
                href={props.input.externalPermalink}
              >
                {props.input.externalPermalink}
              </Link>
            </Text>
            {props.input.dealId && (
              <Text>
                <Strong>Creator Media Link:</Strong>{' '}
                <Link
                  target="_blank"
                  className="text-sm text-kale-green-500 underline hover:text-kale-green-400"
                  href={`/creators/${props.input.creatorId}/post?dealId=${props.input.dealId}`}
                >
                  Upload Here
                </Link>
              </Text>
            )}
          </div>
          <div className="flex justify-center">
            <div className="flex flex-wrap items-center justify-center">
              {showLoaderForMediaComponent ? (
                <Loading />
              ) : (
                <UploadMedia
                  uploadType="POST"
                  metadata={{
                    data: [
                      {
                        key: 'postId',
                        value: props?.input?.postId?.toString(),
                      },
                    ],
                  }}
                />
              )}
            </div>
          </div>
        </BonsaiPanel>
      </div>
      {!props.readOnly && (
        <BonsaiActionPanel
          submitDisabled={selectedOutcome === undefined}
          onSubmit={() => {
            props.onComplete({
              outcome: selectedOutcome,
            });
            setSelectedOutcome(undefined);
            setShowLoaderForMediaComponent(true);
            setTimeout(() => setShowLoaderForMediaComponent(false), 3000);
          }}
          taskDescription={props.queue.taskDescription}
          taskId={props.taskId}
        >
          <OutcomeControls
            onSelected={(outcome) => {
              setSelectedOutcome(outcome);
            }}
            selected={selectedOutcome}
          />
        </BonsaiActionPanel>
      )}
    </>
  );
}
