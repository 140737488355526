import { useMutation } from '@apollo/client';
import { PencilSquareIcon } from '@heroicons/react/24/solid';
import {
  Modal,
  Spinner,
  timeDifferenceForDate,
} from '@kalecard/common';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  SET_POST_REWARD_CATEGORY,
  UPDATE_POST_CHILD,
} from '../graphql/mutations';
import UpdatePostMetricsForm from './forms/UpdatePostMetricsForm';
import PostStatsComponent from './PostStatsComponent';
import DealsSelectMenu from './select-menus/DealsSelectMenu';
import Toggle from './Toggle';
import UploadMedia from './forms/UploadMedia';
import PostRewardCategory from './posts/PostRewardCategory';
import { DealEdge, MediaUrl, Post } from '../__generated__/graphql';

interface PostComponentProps {
  post: Post;
  deals: DealEdge[];
  isDealView?: boolean;
  showToggle?: boolean;
}

export default function PostComponent({
  deals,
  post,
  isDealView = false,
  showToggle = true,
}: PostComponentProps) {
  const [updatePostChild] = useMutation(UPDATE_POST_CHILD);
  const [setPostRewardCategoryMutation] = useMutation(SET_POST_REWARD_CATEGORY);
  const [postRewardCategory, setPostRewardCategory] = useState(
    post.rewardCategory
  );
  const [error, setError] = useState<string | null>(null);
  const [loadingImpressionsOnly, setLoadingImpressionsOnly] =
    useState<boolean>(false);
  const [loadingIneligible, setLoadingIneligible] = useState<boolean>(false);
  const [isUpdatePostMetricsModalOpen, setIsUpdatePostMetricsModalOpen] =
    useState<boolean>(false);

  const { register, handleSubmit } = useForm({});

  const captionLink = (
    <a
      className="text-indigo-600 hover:text-indigo-900"
      href={post.externalPermalink}
      target="_blank"
      rel="noreferrer"
    >
      {post.caption ? post.caption : '(empty caption)'}
    </a>
  );

  const isFeedCarouselPost = post.mediaType === 'CAROUSEL_ALBUM';

  const handleBrandPostToggleChange = async (
    childId: string,
    newVal: boolean
  ) => {
    try {
      await updatePostChild({
        variables: { childId, isBrandPost: newVal },
      });
      // Set an error
      console.error('Error updating post child.');
      setError(
        'Something went wrong, try refreshing this page or try again later.'
      );
    } catch (err) {
      console.error(err);
      setError('Something went wrong, please try again later.');
    }
  };

  const setImpressionsOnly = async (data) => {
    setLoadingImpressionsOnly(true);
    if (data.mainPostId && data.mainPostId !== '') {
      await setPostRewardCategoryMutation({
        variables: {
          postId: post.id,
          rewardCategory: 'IMPRESSIONS_ONLY',
          mainPostId: data.mainPostId,
        },
      });
      setPostRewardCategory('IMPRESSIONS_ONLY');
    }
    setLoadingImpressionsOnly(false);
  };

  const setIneligible = async () => {
    setLoadingIneligible(true);
    await setPostRewardCategoryMutation({
      variables: {
        postId: post.id,
        rewardCategory: 'INELIGIBLE',
      },
    });
    setPostRewardCategory('INELIGIBLE');
    setLoadingIneligible(false);
  };

  const platformLink =
    post.externalPlatform === 'INSTAGRAM'
      ? 'https://www.instagram.com/'
      : 'https://www.tiktok.com/@';
  const externalUserLink = `${platformLink}${
    post?.externalUser?.username ? post.externalUser.username : post.username
  }`;

  function checkCaption(caption: string) {
    // Check if the caption contains the hashtag #bounty or #swaypay
    if (caption) {
      return (
        caption.toLowerCase().includes('bounty') ||
        caption.toLowerCase().includes('swaypay')
      );
    }
  }

  return (
    <tr className="w-full">
      <td className="w-1/3 px-2 py-2">
        <p className="line-clamp-6 whitespace-pre-wrap break-all text-sm font-semibold">
          {captionLink}
        </p>
        <div>
          {checkCaption(post?.caption) && (
            <span className="inline-flex items-center rounded-md bg-orange-100 px-2.5 py-0.5 text-sm font-medium text-orange-800">
              Bounty/Swaypay hashtag detected
            </span>
          )}
          <PostRewardCategory
            postId={post.id}
            rewardCategory={postRewardCategory}
            setPostRewardCategory={setPostRewardCategory}
          />
        </div>
        <div className="text-sm text-gray-500">
          Posted {timeDifferenceForDate(Number(post.createdAt))} by{' '}
          <a
            className="truncate text-sm font-semibold text-indigo-600 hover:text-indigo-900"
            href={externalUserLink}
            target="_blank"
            rel="noreferrer"
          >
            {post?.externalUser?.username
              ? post.externalUser.username
              : post.username}
          </a>
        </div>
        <div className="flex flex-col justify-start space-y-1 text-sm text-gray-500">
          <div>
            {post.externalPlatform} - {post.mediaType} - {post.productType}
          </div>
          <p>
            Post ID: {post.id}
          </p>
        </div>
        {isDealView && deals.length > 0 && (
          <DealsSelectMenu
            post={post}
            deals={deals}
            selectedDeal={post.deal}
          />
        )}
      </td>
      <td className="w-1/4 px-2 py-2 text-sm">
        <div className="flex flex-col space-y-2">
          {post.rewardCategory !== 'IMPRESSIONS_ONLY' && (
            <form
              className="flex flex-col items-start space-y-1"
              onSubmit={handleSubmit(setImpressionsOnly)}
            >
              <input
                type="number"
                step={1}
                {...register('mainPostId')}
                name="mainPostId"
                id="mainPostId"
                className="block rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Main Post ID"
                disabled
              />
              <div className="flex items-center space-x-2">
                <button
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-xs font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  type="submit"
                  disabled={true}
                >
                  Impressions only
                </button>
                {loadingImpressionsOnly && <Spinner size="h-4 w-4" />}
              </div>
            </form>
          )}
          <div className="flex items-center space-x-2">
            <button
              onClick={setIneligible}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-xs font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
              disabled={true}
            >
              Set ineligible
            </button>
            {loadingIneligible && <Spinner size="h-4 w-4" />}
          </div>
        </div>
      </td>
      <td className="w-1/4 px-2 py-2 text-sm">
        <div className="flex pb-2 ">
          <p className="font-bold text-gray-600">Edit metrics</p>
          <PencilSquareIcon className="h-5 w-5 pl-1 text-gray-600" />
        </div>
        <PostStatsComponent post={post} />
      </td>
      <td className="w-1/3 px-2 py-2">
        {!isDealView ? (
          <DealsSelectMenu
            post={post}
            deals={deals}
            selectedDeal={post.deal}
          />
        ) : (
          <div>
            <div className="flex flex-wrap items-center justify-between">
              {post.mediaUrls.length > 0 ? (
                post.mediaUrls.map((mediaUrl: MediaUrl, index) => (
                  <div
                    key={index}
                    className="flex items-center"
                  >
                    <UploadMedia
                      initialUrl={mediaUrl.url}
                      initialMediaType={mediaUrl.mediaType}
                      uploadType="POST"
                      metadata={{
                        data: [{ key: 'postId', value: post.id }],
                      }}
                    />
                    {isFeedCarouselPost && showToggle && (
                      <div className="flex flex-col items-center">
                        <span className="text-sm">Brand Post</span>
                        <Toggle
                          enabled={mediaUrl?.carouselItem.isBrandPost}
                          onChange={(newVal: boolean) =>
                            handleBrandPostToggleChange(
                              mediaUrl?.carouselItem.id,
                              newVal
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <UploadMedia
                  uploadType="POST"
                  metadata={{
                    data: [{ key: 'postId', value: post.id }],
                  }}
                />
              )}
            </div>
          </div>
        )}
      </td>
      <Modal
        isModalOpen={isUpdatePostMetricsModalOpen}
        onClose={() => setIsUpdatePostMetricsModalOpen(false)}
      >
        <UpdatePostMetricsForm
          key={post.id}
          postId={post.id}
          onSubmit={() => setIsUpdatePostMetricsModalOpen(false)}
        />
      </Modal>
    </tr>
  );
}
