// Exits: 7
// Impressions: 25
// Reach: 20
// Replies: 3
// Taps Back: 1
// Taps Forward: 14
// Likes: 0

import { Post } from '../__generated__/graphql';

// Comments: 0
export default function PostStatsComponent({ post }: { post: Post }) {
  switch (post.productType) {
    case 'CLIPS':
      return (
        <div className="flex flex-col">
          <p>{post.impressions} impressions</p>
          <p>{post.playCount} plays</p>
          <p>{post.likeCount} likes</p>
          <p>{post.commentCount} comments</p>
          <p>{post.savedCount} saves</p>
        </div>
      );
    case 'FEED':
      return (
        <div className="flex flex-col">
          <p>{post.impressions} impressions</p>
          <p>{post.likeCount} likes</p>
          <p>{post.commentCount} comments</p>
          <p>{post.reach} reach</p>
          <p>{post.savedCount} saves</p>
          <p>{post.engagement} engagement</p>
        </div>
      );
    case 'STORY':
      return (
        <div className="flex flex-col">
          <p>{post.impressions} impressions</p>
          <p>{post.replies} replies</p>
          <p>{post.reach} reach</p>
          <p>{post.backwardsCount} taps back</p>
          <p>{post.forwardsCount} taps forward</p>
          <p>{post.exitCount} exits</p>
        </div>
      );
    default:
      return (
        <div className="flex flex-col">
          <p>{post.likeCount} likes</p>
          <p>{post.commentCount} comments</p>
        </div>
      );
  }
}
