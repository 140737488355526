import { useLazyQuery, useQuery } from '@apollo/client';
import { Spinner, Loading, Modal } from '@kalecard/common';
import { useEffect, useState } from 'react';
import { DEALS, POSTS } from '../../graphql/queries';
import NewPostForm from '../forms/NewPostForm';
import PostComponent from '../PostComponent';
import {
  Creator,
  DealConnection,
  PostConnection,
  PostEdge,
} from '../../__generated__/graphql';

interface CreatorPostsProps {
  userId: string;
  dealId?: string;
  creator?: Creator;
}

interface PostsData {
  posts: PostConnection;
}

interface DealsData {
  deals: DealConnection;
}

export default function CreatorPosts({
  userId,
  dealId = null,
}: CreatorPostsProps) {
  const [edges, setEdges] = useState<PostEdge[]>([]);
  const [after, setAfter] = useState<string>(null);
  const [isNewPostModalOpen, setIsNewPostModalOpen] = useState(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);

  const loadPosts = (data: PostsData) => {
    console.log(data);
    if (data.posts) {
      setHasNextPage(data.posts.pageInfo.hasNextPage);
      setAfter(data.posts.edges[data.posts.edges.length - 1]?.cursor);
      setEdges([...edges, ...data.posts.edges]);
    }
  };

  const [getPosts, { loading }] = useLazyQuery<PostsData>(POSTS, {
    fetchPolicy: 'no-cache',
    onCompleted: loadPosts,
  });

  const { data: dealsData } = useQuery<DealsData>(DEALS, {
    variables: {
      first: 20,
      userId,
    },
  });

  useEffect(() => {
    setEdges([]);
    setAfter(null);
    setHasNextPage(false);
    getPosts({
      variables: {
        first: 10,
        userId,
        dealId,
      },
    });
  }, [dealId]);

  var listFooter = null;
  if (hasNextPage) {
    listFooter = (
      <button
        className="rounded border border-green-500 bg-transparent px-6 py-2 text-center font-semibold text-green-700 hover:border-transparent hover:bg-green-500 hover:text-white"
        onClick={() =>
          getPosts({
            variables: {
              first: 10,
              after,
              userId,
            },
          })
        }
      >
        Load more
      </button>
    );
  }

  if (loading && edges.length === 0) {
    return <Loading />;
  }

  if (loading) {
    listFooter = (
      <div className="flex flex-wrap content-center">
        <Spinner size="h-6 w-6" />
      </div>
    );
  }

  return (
    <>
      <div className="my-4 flex flex-col items-center">
        <div className="mb-2 w-full overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            {dealId === null && (
              <div className="mb-4 flex">
                <button
                  onClick={() => setIsNewPostModalOpen(true)}
                  className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
                >
                  Create New Post
                </button>
              </div>
            )}
            {/* {dealsData && dealId !== null && <div>
              <DealHeader
                          deal={dealsData.deals.edges.find((deal) => deal.node.id === dealId).node}
                        />
              </div>} */}
            <div className="overflow-hidden border-2 border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full table-fixed divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Caption
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Actions
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Stats
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      {dealId === null ? 'Deal' : 'Media'}
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {dealsData &&
                    edges.map((edge) => (
                      <PostComponent
                        key={edge.node.id}
                        post={edge.node}
                        deals={dealsData.deals.edges}
                        isDealView={dealId !== null}
                      />
                    ))}
                  {edges.length === 0 && (
                    <tr>
                      <td className="px-6 py-4">No posts!</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {listFooter}
      </div>
      <Modal
        isModalOpen={isNewPostModalOpen}
        onClose={() => setIsNewPostModalOpen(false)}
      >
        <NewPostForm userId={userId} />
      </Modal>
    </>
  );
}
