import { TableCell, TableRow } from "../catalyst/table";
import { Input } from "../catalyst/input";
import { Button } from "../catalyst/button";
import {
  BrandCategory,
  Spinner,
  ChallengeTemplate
} from "@kalecard/common";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import {
  CREATE_CHALLENGE_TEMPLATE,
  UPDATE_CHALLENGE_TEMPLATE,
} from "../../graphql/mutations";
import { useState } from "react";
import { CHALLENGE_TEMPLATES } from "../../graphql/queries";
import { Badge } from "../catalyst/badge";
import { Strong, Text } from "../catalyst/text";
import BrandCategoryDropdown from "../brands/BrandCategoryDropdown";
import { Field } from "../catalyst/fieldset";
import { Tooltip } from "flowbite-react";
import { ChallengeTemplateStatus } from "../templates/ChallengeTemplateRow";

function FormattedTemplate({ template }) {
  const parts = template?.split("{{brand_name}}");

  return (
    <div className="flex flex-wrap space-x-1">
      {parts?.map((part, index) => (
        <>
          <Text>{part}</Text>
          {index !== parts.length - 1 && <BrandBadge />}
        </>
      ))}
    </div>
  );
}

function BrandBadge() {
  return <Badge color="green">BRAND</Badge>;
}

interface ChallengeTemplateInput {
  name: string;
  template: string;
}

const handleBrandBadgeClick = (getValues, setValue) => {
  const template = getValues("template");
  const newTemplate = template + "{{brand_name}}";
  setValue("template", newTemplate);
};

export function ChallengeTemplateComponent({
  challengeTemplate,
  brandCategories,
}: {
  challengeTemplate: ChallengeTemplate;
  brandCategories: BrandCategory[];
}) {
  const [isEditView, setIsEditView] = useState(false);

  return isEditView ? (
    <ChallengeTemplateForm
      key={challengeTemplate.id}
      challengeTemplate={challengeTemplate}
      onSave={() => setIsEditView(false)}
      isEditForm={true}
      brandCategories={brandCategories}
    />
  ) : (
    <TableRow key={challengeTemplate.id}>
      <TableCell>
        <Strong>{challengeTemplate.title}</Strong>
      </TableCell>
      <TableCell>
        <FormattedTemplate template={challengeTemplate.template} />
      </TableCell>
      <TableCell>
        <ChallengeTemplateStatus challengeTemplate={challengeTemplate} />
      </TableCell>
      {/* Brand Categories */}
      <TableCell className="flex space-x-2">
        {challengeTemplate.brandCategories?.map((brandCategory, index) => (
          <div hidden={index > 1}>
            <Badge color="indigo" key={brandCategory.id}>
              {brandCategory.name}
            </Badge>
          </div>
        ))}
        {challengeTemplate.brandCategories?.length > 2 && (
          <Tooltip
          content={
            <div>
              {challengeTemplate.brandCategories.slice(2).map((brandCategory) => (
                <div key={brandCategory.id}>{brandCategory.name}</div>
              ))}
            </div>
          }
         >
           <Badge color="indigo">
             +{challengeTemplate.brandCategories.length - 2}
           </Badge>
         </Tooltip>
        )}
      </TableCell>
      <TableCell
        className="font-medium text-kale-green-500 hover:cursor-pointer"
        onClick={() => setIsEditView(true)}
      >
        Edit
      </TableCell>
    </TableRow>
  );
}

export function ChallengeTemplateForm({
  challengeTemplate,
  onSave,
  isEditForm,
  brandCategories,
}: {
  challengeTemplate?: ChallengeTemplate;
  onSave?: () => void;
  isEditForm: boolean;
  brandCategories: BrandCategory[];
}) {
  const [selectedBrandCategories, setSelectedBrandCategories] = useState<
    BrandCategory[]
  >(challengeTemplate?.brandCategories ?? []);
  const { reset, register, handleSubmit, getValues, setValue } =
    useForm<ChallengeTemplateInput>({
      defaultValues: isEditForm
        ? {
          name: challengeTemplate.title,
          template: challengeTemplate.template,
        }
        : {},
    });

  const [mutate, { loading }] = useMutation(
    isEditForm ? UPDATE_CHALLENGE_TEMPLATE : CREATE_CHALLENGE_TEMPLATE,
    { refetchQueries: [CHALLENGE_TEMPLATES] }
  );

  const onSubmit = (data) => {
    mutate({
      variables: {
        ...(isEditForm && { challengeTemplateId: challengeTemplate.id }),
        ...(!isEditForm && { templateType: "THEME", shouldCreateChallengeRequests: false }),
        title: data.name,
        template: data.template,
        brandCategoryIds: selectedBrandCategories.map(
          (brandCategory) => brandCategory.id
        )
      },
    })
      .then((data) => {
        console.log(data);
        reset({
          name: null,
          template: null,
        });
        setSelectedBrandCategories([]);
        isEditForm && onSave();
      })
      .catch(console.error);
  };

  return (
    <TableRow>
      <TableCell>
        <Field>
          <Input
            className="font-medium"
            name="name"
            placeholder="Name"
            {...register("name", { required: true })}
          />
        </Field>
      </TableCell>

      <TableCell className="space-y-2">
        <Field>
          <Input
            name="template"
            placeholder="Template"
            {...register("template", { required: true })}
          />
        </Field>
        <div
          className="inline-flex hover:cursor-pointer"
          onClick={() => handleBrandBadgeClick(getValues, setValue)}
        >
          <BrandBadge />
        </div>
      </TableCell>

      <TableCell className="space-y-2">
        <div className="flex items-center space-x-2">
          <Strong>Brand Categories</Strong>
          <BrandCategoryDropdown
            brandCategories={brandCategories}
            selectedBrandCategories={selectedBrandCategories}
            setSelectedBrandCategories={setSelectedBrandCategories}
          />
        </div>
      </TableCell>

      <TableCell>
        <Field className="flex space-x-2">
          <Button
            type="button"
            color="emerald"
            className="hover:cursor-pointer"
            onClick={handleSubmit(onSubmit)}
          >
            {challengeTemplate ? "Save" : "Add"}
          </Button>
          {loading && (
            <div className="flex justify-center pt-2">
              <Spinner size={"h-5 w-5"} />
            </div>
          )}
        </Field>
      </TableCell>
    </TableRow>
  );
}
