import { KaleAdmin, Modal, Spinner } from "@kalecard/common";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../catalyst/table";
import { useQuery } from "@apollo/client";
import { ADMINS, AdminsInterface } from "../../graphql/queries";
import { FormattedRole } from "./AdminRole";
import { useState } from "react";
import EditAdminForm from "../forms/EditAdminForm";
import RemoveAdminForm from "../forms/RemoveAdminForm";
import { Dialog } from "../catalyst/dialog";

export default function AdminsTable() {
  const { data, loading, refetch } =
    useQuery<AdminsInterface>(ADMINS);

  const [currentAdmin, setCurrentAdmin] = useState(null);
  const [isEditAdminOpen, setIsEditAdminOpen] = useState(false);
  const [isRemoveAdminOpen, setIsRemoveAdminOpen] = useState(false);

  const handleEditAdminClick = (admin: KaleAdmin) => {
    setCurrentAdmin(admin);
    setIsEditAdminOpen(true);
  }

  const handleRemoveAdminClick = (admin: KaleAdmin) => {
    setCurrentAdmin(admin);
    setIsRemoveAdminOpen(true);
  }

  const handleCloseEditAdmin = () => {
    setIsEditAdminOpen(false);
  }

  return (
    !loading
      ? (
        <>
          <Table striped className="border rounded-md p-4">
            {/* Headers */}
            <TableHead>
              <TableRow className="text-black">
                <TableHeader>Name</TableHeader>
                <TableHeader>Email</TableHeader>
                <TableHeader>Role</TableHeader>
                <TableHeader></TableHeader>
              </TableRow>
            </TableHead>

            {/* Table Body */}
            <TableBody>
              {data?.admins?.map((admin) => (
                <TableRow key={admin.id}>
                  <TableCell className="font-medium">{admin.name}</TableCell>
                  <TableCell>{admin.email}</TableCell>
                  <TableCell className="text-gray-500 capitalize"><FormattedRole role={admin.role} /></TableCell>
                  <TableCell className="flex space-x-6">
                    <p className="font-medium text-kale-green-500 hover:cursor-pointer" onClick={() => handleEditAdminClick(admin)}>Edit</p>
                    <p className="font-medium text-red-500 hover:cursor-pointer" onClick={() => handleRemoveAdminClick(admin)}>Remove</p>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Dialog
            open={isEditAdminOpen}
            onClose={handleCloseEditAdmin}
            size="5xl"
          >
            <EditAdminForm admin={currentAdmin} onSubmit={handleCloseEditAdmin} />
          </Dialog>
          <Modal
            isModalOpen={isRemoveAdminOpen}
            onClose={() => setIsRemoveAdminOpen(false)}
            className="w-fit"
          >
            <RemoveAdminForm admin={currentAdmin} onSubmit={() => setIsRemoveAdminOpen(false)} />
          </Modal>
        </>)
      : <div className="flex flex-wrap content-center justify-center pr-2">
        <Spinner size="h-6 w-6" />
      </div>
  );
}