import { CommonToolProps } from '../BonsaiTypes';
import { BonsaiActionPanel } from '../BonsaiContainer';
import {
  BonsaiContextLinkType,
  bonsaiContextLinkTypeForExternalUrl,
  BonsaiLink,
  BonsaiPanel,
  BonsaiPanelHeader,
} from '@kalecard/common';
import React from 'react';
import { Textarea } from '../../catalyst/textarea';
import { clsx } from 'clsx';
import { Strong, Text, TextLink } from '../../catalyst/text';
import { Badge } from '../../catalyst/badge';

const options = new Set([
  'Good lighting',
  'Good camera quality',
  "Doesn't show face",
  'Bad or missing bio',
  'Bad or missing profile picture',
  'Poor lighting',
  'Bad camera quality',
  'Not aesthetic content',
  'Too much text on screen',
  'Strong camera presence',
  'UGC account',
  'Moms',
  'College student',
]);

enum AccountStatus {
  CONNECTED = 'CONNECTED',
  NOT_CONNECTED = 'NOT_CONNECTED',
}

export interface Account {
  url: string;
  platform: string;
  status?: AccountStatus;
}

interface CreatorReviewOutcome {
  notes: string;
  outcome: string;
  selectedReasons: string[];
}

interface BonsaiBundleData {
  adminId: string;
  adminName?: string;
  completedAt?: string;
  createdAt?: string;
  id: string;
  outcome: CreatorReviewOutcome;
}

interface KaleScoreLogEntry {
  oldKaleScore: number;
  newKaleScore: number;
  triggerSignal: string;
  createdAt: string;
  bonsaiBundleData?: BonsaiBundleData;
}

interface AccountReviewInput {
  creatorId: string;
  accounts: Account[];
  reasons: string[];
  outcome?: AccountReviewOutput;
  kaleScoreHistory?: KaleScoreLogEntry[];
  triggerSignal?: string;
}

interface AccountReviewOutput {
  notes: string;
  selectedReasons: string[];
  outcome: AccountReviewOutcome;
}

enum AccountReviewOutcome {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  BLOCKED = 'BLOCKED',
}

interface AccountReviewProps
  extends CommonToolProps<AccountReviewInput, AccountReviewOutput> {}

export default function AccountReviewTool(props: AccountReviewProps) {
  const [state, setState] = React.useState<{
    selectedReasons: Set<string>;
    notes: string;
    outcome?: AccountReviewOutcome;
  }>(() => ({
    selectedReasons: new Set(props.input.outcome?.selectedReasons ?? []),
    notes: props.input.outcome?.notes ?? '',
    outcome: props.input.outcome?.outcome,
  }));

  const readonly = !!props.input.outcome;
  const blockDisabled = !!props.input.outcome;

  React.useEffect(() => {
    if (props.input.outcome) {
      setState({
        selectedReasons: new Set(props.input.outcome.selectedReasons),
        notes: props.input.outcome.notes,
        outcome: props.input.outcome.outcome,
      });
    }
  }, [props.input.outcome]);

  const resetState = () => {
    setState({
      selectedReasons: new Set(),
      notes: '',
      outcome: undefined,
    });
  };

  const handleReasonToggle = (option: string) => {
    setState((prev) => {
      const newSelectedReasons = new Set(prev.selectedReasons);
      if (newSelectedReasons.has(option)) {
        newSelectedReasons.delete(option);
      } else {
        newSelectedReasons.add(option);
      }
      return { ...prev, selectedReasons: newSelectedReasons };
    });
  };

  const unionedOptions = new Set([
    ...(props.input.outcome?.selectedReasons ?? []),
    ...Array.from(options),
  ]);

  return (
    <div className="flex flex-col gap-4">
      {props.input.triggerSignal && (<Text><Strong>Reason for creator account review: </Strong>{formatString(props.input.triggerSignal)}</Text>)}
   
      <div className="grid grid-cols-3 grid-rows-[auto,auto] gap-6 min-h-[560px]">
        {/* Social Accounts Panel */}
        <div className="col-span-1 row-span-1">
            <BonsaiPanel>
              <div className="h-full flex-row justify-center p-4">
                <BonsaiPanelHeader>
                  <div className="flex flex-row justify-between">
                    <BonsaiLink
                      type={BonsaiContextLinkType.CREATOR}
                      url={`/creators/${props.input.creatorId}/messages`}
                    />
                    Social Accounts
                    <div className="w-12" />
                  </div>
                </BonsaiPanelHeader>
                <div className="mt-8 flex flex-col gap-6">
                  {props.input.accounts.map((account) => (
                    <div className="flex flex-row items-center gap-2">
                      <BonsaiLink
                        type={bonsaiContextLinkTypeForExternalUrl(account.url)}
                        url={account.url}
                      />
                      <div className="flex-col gap-2">
                        <TextLink
                          className="pl-2"
                          href={account.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {account.url}
                        </TextLink>
                        {account.status && (
                          <Badge
                            className={'ml-2'}
                            color={
                              account.status === AccountStatus.CONNECTED
                                ? 'green'
                                : 'red'
                            }
                          >
                            {account.status === AccountStatus.CONNECTED
                              ? 'Connected'
                              : 'Not Connected'}
                          </Badge>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </BonsaiPanel>
        </div>

        {/* Reasons Panel */}
        <div className="col-span-1 row-span-1">
            <BonsaiPanel>
              <div className="h-full flex-row justify-center p-4">
                <BonsaiPanelHeader>Reasons</BonsaiPanelHeader>
                <div className="mt-4 grid grid-cols-2 gap-4">
                  {Array.from(unionedOptions).map((option) => (
                    <OptionButton
                      key={option}
                      disabled={readonly}
                      selected={state.selectedReasons.has(option)}
                      onClick={() => handleReasonToggle(option)}
                    >
                      {option}
                    </OptionButton>
                  ))}
                </div>
              </div>
            </BonsaiPanel>
        </div>

        {/* Review Notes Panel */}
        <div className={`col-span-${props.input.kaleScoreHistory?.length > 0 ? 2 : 1} row-span-1`} style={{ minHeight: '100px' }}>
          <Textarea
            className="h-full"
            disabled={readonly}
            placeholder="Add some review notes here"
            resizable={false}
            value={state.notes}
            onChange={(e) =>
              setState((prev) => ({ ...prev, notes: e.target.value }))
            }
          />
        </div>

        {/* Kale Score History Panel (only shown if history exists) */}
        {props.input.kaleScoreHistory?.length > 0 && (
          <div className="col-start-3 col-span-1 row-span-2 row-start-1" style={{ maxHeight: '800px' }}>
            <BonsaiPanel>
              <div className="p-4 space-y-4 overflow-y-auto">
                <BonsaiPanelHeader>Creator's Kale Score History</BonsaiPanelHeader>
                <div className="space-y-4 whitespace-nowrap">
                  {props.input.kaleScoreHistory.map((kaleScoreLogEntry) =>
                    <KaleScoreLogEntry kaleScoreLogEntry={kaleScoreLogEntry} />
                  )}
                </div>
              </div>
            </BonsaiPanel>
          </div>
        )}
      </div>

      <BonsaiActionPanel
        submitDisabled={!state.outcome}
        onSubmit={async () => {
          props.onComplete({
            notes: state.notes,
            selectedReasons: Array.from(state.selectedReasons),
            outcome: state.outcome!,
          });
          if (!props.input.outcome) {
            resetState();
          }
        }}
        taskDescription={props.queue?.taskDescription}
        taskId={props.taskId}
      >
        <div className="flex flex-row gap-2">
          <AccountReviewOutcomeControls
            selected={state.outcome}
            onSelected={(outcome) => setState((prev) => ({ ...prev, outcome }))}
            blockDisabled={blockDisabled}
          />
        </div>
      </BonsaiActionPanel>
    </div>
  );
}

interface AccountReviewOutcomeControlsProps {
  selected?: AccountReviewOutcome;
  onSelected: (outcome: AccountReviewOutcome) => void;
  disabled?: boolean;
  blockDisabled?: boolean;
}

export function AccountReviewOutcomeControls(
  props: AccountReviewOutcomeControlsProps
) {
  const accepted = props.selected === AccountReviewOutcome.ACCEPTED;
  const rejected = props.selected === AccountReviewOutcome.REJECTED;
  const blocked = props.selected === AccountReviewOutcome.BLOCKED;
  return (
    <>
      <span className="isolate mr-1 inline-flex rounded-md shadow-sm">
        <button
          disabled={props.disabled}
          type="button"
          className={clsx(
            'relative inline-flex items-center rounded-l-lg px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-kale-green-400/25 focus:z-10',
            accepted ? 'bg-green-500 text-white' : 'bg-white',
            !props.disabled && !accepted && 'hover:bg-gray-50',
            props.disabled && 'cursor-not-allowed opacity-50'
          )}
          onClick={() => props.onSelected(AccountReviewOutcome.ACCEPTED)}
        >
          Accept
        </button>
        <button
          disabled={props.disabled}
          type="button"
          className={clsx(
            'relative -ml-px inline-flex items-center px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-kale-green-400/25 focus:z-10',
            rejected ? 'bg-amber-500 text-white' : 'bg-white',
            !props.disabled && !rejected && 'hover:bg-gray-50',
            props.disabled && 'cursor-not-allowed opacity-50'
          )}
          onClick={() => props.onSelected(AccountReviewOutcome.REJECTED)}
        >
          Reject
        </button>
        <button
          disabled={props.disabled || props.blockDisabled}
          type="button"
          className={clsx(
            'relative -ml-px inline-flex items-center rounded-r-lg px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-kale-green-400/25 focus:z-10',
            blocked ? 'bg-red-500 text-white' : 'bg-white',
            !props.disabled && !blocked && 'hover:bg-gray-50',
            props.disabled && 'cursor-not-allowed opacity-50'
          )}
          onClick={() => props.onSelected(AccountReviewOutcome.BLOCKED)}
        >
          Block creator
        </button>
      </span>
    </>
  );
}

function OptionButton({
  children,
  selected,
  disabled,
  onClick,
}: {
  children: React.ReactNode;
  selected: boolean;
  disabled: boolean;
  onClick: () => void;
}) {
  return (
    <button
      onClick={onClick}
      className={clsx(
        'flex h-16 items-center justify-center rounded-md border border-slate-300 p-2 text-sm font-bold',
        !selected && 'bg-slate-50 text-slate-600',
        selected && 'border-slate-400 bg-slate-300 text-slate-700 shadow-inner',
        !disabled && 'hover:bg-slate-200',
        disabled && 'cursor-not-allowed opacity-70'
      )}
    >
      {children}
    </button>
  );
}

function KaleScoreLogEntry({
  kaleScoreLogEntry
} : {
  kaleScoreLogEntry: KaleScoreLogEntry
}) {
  return (
    <div className="space-y-2 border p-2 rounded-md">
      <div className="text-center pb-2 text-md space-y-1">
        <Text className="underline">{formatString(kaleScoreLogEntry.triggerSignal)}</Text>

        {kaleScoreLogEntry.bonsaiBundleData?.outcome.outcome && (
          <Badge color={`${kaleScoreLogEntry.bonsaiBundleData?.outcome.outcome === "ACCEPTED" ? "emerald" : "red"}`}>
            {formatString(kaleScoreLogEntry.bonsaiBundleData?.outcome.outcome)}
          </Badge>
        )}

      <p className="text-xs text-gray-500">
        {kaleScoreLogEntry.bonsaiBundleData?.adminName && `${kaleScoreLogEntry.bonsaiBundleData?.adminName} on `} 
        {new Date(Number(kaleScoreLogEntry.createdAt)).toLocaleDateString('en-us', {
          month: 'long', 
          day: 'numeric',
          year: 'numeric'
        })}
      </p>
      </div>

      <div>
        <div className="flex space-x-2">
          <Text><Strong>New Score:</Strong></Text>
          <Text>{kaleScoreLogEntry.newKaleScore}</Text>
        </div>

        <div className="flex space-x-2">
          <Text><Strong>Old Score:</Strong></Text>
          <Text>{kaleScoreLogEntry.oldKaleScore}</Text>
        </div>
      </div>
      
      {kaleScoreLogEntry.bonsaiBundleData && (
        <>
          <div>
            <Text><Strong>Selected Reasons:</Strong></Text>
            <Text className="text-wrap">{kaleScoreLogEntry.bonsaiBundleData.outcome.selectedReasons.join(", ")}</Text>
          </div>

          {kaleScoreLogEntry.bonsaiBundleData.outcome.notes && (
            <div>
              <Text><Strong>Notes:</Strong></Text>
              <Text>{kaleScoreLogEntry.bonsaiBundleData.outcome.notes}</Text>
            </div>
          )}
        </>
      )}
    </div>
  );
}

function formatString(str) {
  return str
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}